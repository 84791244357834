import { AppThunk, createAsyncAction } from 'typesafe-actions';

import { getDictionary } from '@travel/i18n';
import { getTranslation } from '@travel/translation';

import { updateSEOData } from 'store/seo/actions';

import {
  AreaDetailsErrors,
  AreaDetailsItems,
  AreaInfoResponse,
  AreaInfoSearchRequestBody,
  AreaRelatedItems,
  AreaRelatedRequestBody,
  SearchRequestBody,
} from 'AreaDetails-Types';
import paths from 'core/universalRouter/paths';

import { fetchAreaDetailsItems, fetchAreaInfoItems, fetchAreaRelatedItems } from './api';
import { getItems } from './selectors';
import { defaultAreaRelated, isCityLevelAreaOrSmaller } from './utils';

export const fetchAreaDetailsAsync = createAsyncAction(
  'FETCH_AREA_DETAILS_REQUEST',
  'FETCH_AREA_DETAILS_SUCCESS',
  'FETCH_AREA_DETAILS_FAILURE',
)<undefined, AreaDetailsItems, AreaDetailsErrors>();

export const fetchAreaInfoAsync = createAsyncAction(
  'FETCH_AREA_INFO_REQUEST',
  'FETCH_AREA_INFO_SUCCESS',
  'FETCH_AREA_INFO_FAILURE',
)<undefined, AreaInfoResponse, AreaDetailsErrors>();

export const fetchAreaRelatedAsync = createAsyncAction(
  'FETCH_AREA_RELATED_REQUEST',
  'FETCH_AREA_RELATED_SUCCESS',
  'FETCH_AREA_RELATED_FAILURE',
)<undefined, AreaRelatedItems, AreaDetailsErrors>();

export const fetchAreaDetails = (
  req: SearchRequestBody,
  ignoreCommonErrorHandler?: boolean,
): AppThunk => async (dispatch, _getState, { apiClient }) => {
  dispatch(fetchAreaDetailsAsync.request());

  try {
    const response = await fetchAreaDetailsItems(apiClient, req, ignoreCommonErrorHandler);
    dispatch(fetchAreaDetailsAsync.success(response));
  } catch (error) {
    dispatch(fetchAreaDetailsAsync.failure(error as AreaDetailsErrors));
  }
};

export const fetchAreaInfo = (
  req: AreaInfoSearchRequestBody,
  ignoreCommonErrorHandler?: boolean,
): AppThunk => async (dispatch, _getState, { apiClient }) => {
  dispatch(fetchAreaInfoAsync.request());

  try {
    const response = await fetchAreaInfoItems(apiClient, req, ignoreCommonErrorHandler);
    dispatch(fetchAreaInfoAsync.success(response));
  } catch (error) {
    dispatch(fetchAreaInfoAsync.failure(error as AreaDetailsErrors));
  }
};

export const fetchAreaRelated = (
  req: AreaRelatedRequestBody,
  ignoreCommonErrorHandler?: boolean,
): AppThunk => async (dispatch, _getState, { apiClient }) => {
  dispatch(fetchAreaRelatedAsync.request());

  if (!isCityLevelAreaOrSmaller(req.pathId)) {
    dispatch(fetchAreaRelatedAsync.success(defaultAreaRelated));
    return;
  }

  try {
    const response = await fetchAreaRelatedItems(apiClient, req, ignoreCommonErrorHandler);
    dispatch(fetchAreaRelatedAsync.success(response));
  } catch (error) {
    dispatch(fetchAreaRelatedAsync.failure(error as AreaDetailsErrors));
  }
};

export const fetchSEOdata = (pathId: string): AppThunk<void> => (dispatch, getState) => {
  const state = getState();
  const { areaName, isSeoIndexExpected } = getItems(state);
  const dictionary = getDictionary(state);

  dispatch(
    updateSEOData({
      title: getTranslation({ id: 'SEO.Area.Title', data: { place: areaName } }, dictionary).join(
        '',
      ),
      description: getTranslation(
        {
          id: 'SEO.Area.Meta.Description',
          data: { place: areaName },
        },
        dictionary,
      ).join(''),
      isIndexedByDefault: isSeoIndexExpected,
      canonicalPath: paths.area.canonicalPathResolver(pathId),
    }),
  );
};
