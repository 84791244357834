import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { useApiClient } from '@travel/api-client';
import { getCurrency } from '@travel/i18n';
import { ArrowDown, ArrowUp, Checkmark } from '@travel/icons/ui';
import {
  MOBILE_SCREEN,
  useDeviceType,
  useDialogHandler,
  useGetTranslation,
} from '@travel/traveler-core/hooks';
import { CouponDetail, FlatButton } from '@travel/ui';

import { linkNewCouponItem } from 'store/acquiredCoupons/api';

import { Translate } from 'core/translate';
import { useCouponDetailLabels } from 'hooks/useCouponLabels';
import { CouponDetails } from 'SalesPromotion-Types';

import CouponHeader from '../CouponHeader';

import styles from './couponItem.module.scss';

type Props = {
  className?: string;
  /** id of sales promotion */
  salesPromotionId: string;
  /** details of the coupon */
  couponDetail: CouponDetails;
  /** flag whether coupon is acquired or not */
  isAcquired?: boolean;
  /** flag to make detail open */
  isDetailInitiallyOpen?: boolean;
  /** event handler for successfully link coupon */
  handleCouponLinkSuccess: () => void;
  /** flag whether coupon button is show or not */
  shouldShowCouponButton?: boolean;
  /** flag whether Accordion button is show or not */
  shouldShowAccordionButton?: boolean;
};

export function CouponItem(props: Props) {
  const {
    salesPromotionId,
    couponDetail,
    isAcquired,
    isDetailInitiallyOpen,
    handleCouponLinkSuccess,
    shouldShowCouponButton = true,
    shouldShowAccordionButton = true,
    ...rest
  } = props;
  const { isOpen: isDetailOpen, onToggle: onDetailToggle } = useDialogHandler(
    isDetailInitiallyOpen || false,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isNewCouponLinked, setIsNewCouponLinked] = useState(false);
  const apiClient = useApiClient();
  const isSP = useDeviceType() === MOBILE_SCREEN;

  const currency = useSelector(getCurrency);

  const getTranslation = useGetTranslation();
  const couponDetailLabels = useCouponDetailLabels(
    getTranslation,
    currency,
    couponDetail?.localCurrency,
  );

  const isShowGetCouponButton = !isAcquired && (!isNewCouponLinked || isLoading);

  const linkCoupon = async () => {
    setIsLoading(true);
    try {
      await linkNewCouponItem(apiClient, salesPromotionId, false);
      handleCouponLinkSuccess();
      setIsNewCouponLinked(true);
    } catch (error) {
      // do nothing
    }
    setIsLoading(false);
  };

  const accordionButton = (
    <button
      onClick={onDetailToggle}
      className={styles.accordionButton}
      data-testid="providerInfo-ratePlanList-couponItem-accordionButton"
    >
      <Translate
        id="Coupon_Details.Details"
        condition={() => `status == ${isDetailOpen ? 'details_shown' : 'details_hidden'}`}
        className={styles.buttonText}
      />
      {isDetailOpen ? (
        <ArrowUp size={18} color={'cilantro'} />
      ) : (
        <ArrowDown size={18} color={'cilantro'} />
      )}
    </button>
  );

  const couponButton = isShowGetCouponButton ? (
    <div className={styles.buttonWrapper}>
      <FlatButton
        className={styles.button}
        onClick={linkCoupon}
        isLoading={isLoading}
        data-testid="providerInfo-ratePlanList-couponItem-getCouponButton"
      >
        <Translate id="Coupon_List.Get_Coupon.Title" />
      </FlatButton>
    </div>
  ) : (
    <div className={styles.buttonWrapper}>
      <div
        className={styles.appliedMessage}
        data-testid="providerInfo-ratePlanList-couponItem-appliedMessage"
      >
        {/* <Translate id="Coupon_List.Get_Coupon.Title" /> */}
        <div className={styles.checkWrapper}>
          <Checkmark size={10} color="white" />
        </div>
        <Translate
          id="Provider_Info.Room_List.Plan_Info.Coupons.Applied"
          condition={() => 'count == 1'}
        />
      </div>
    </div>
  );

  return (
    <div data-testid="couponItem-wrapper" className={styles.wrapper} {...rest}>
      <div>
        <CouponHeader
          couponDetail={couponDetail}
          currency={currency}
          localCurrency={couponDetail?.localCurrency}
        />
      </div>
      <div className={styles.detailWrapper}>
        {shouldShowCouponButton && couponButton}
        <div className={styles.accordion}>
          {isDetailOpen && (
            <CouponDetail
              className={styles.couponDetail}
              couponTextClassName={styles.couponText}
              couponDetail={couponDetail}
              currency={currency}
              labels={couponDetailLabels}
              customHeaderTitle={<Translate id="Coupon_Details.Conditions.Title" />}
            />
          )}
          {isSP && shouldShowAccordionButton && accordionButton}
        </div>
      </div>
    </div>
  );
}
