import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ArrowRightThick, Close, RtGreenSquareLogo } from '@travel/icons/ui';
import { ADJUST_APP_LINK } from '@travel/traveler-core/constants/common';
import { getRanData } from '@travel/traveler-core/store/ranStore/selectors';
import { sessionSetItem } from '@travel/traveler-core/utils';
import { addRanDataToAppUrl } from '@travel/traveler-core/utils/helper';
import { Link } from '@travel/ui';
import { cx } from '@travel/utils';

import { fetchBanner } from 'store/banner/actions';
import { getIsIOS } from 'store/userAgent/selectors';

import { Translate } from 'core/translate';

import styles from './smartBanner.module.scss';

type Props = {
  setShouldShowSmartBanner: (isOpen: boolean) => void;
};

const SmartBanner = (props: Props) => {
  const { setShouldShowSmartBanner } = props;
  const isIOS = useSelector(getIsIOS);
  const ranData = useSelector(getRanData);
  const dispatch = useDispatch();
  const [isRemoved, setIsRemoved] = useState(false);

  const appUrl = addRanDataToAppUrl(ADJUST_APP_LINK, isIOS, ranData);

  const removeBanner = () => {
    setIsRemoved(!isRemoved);
    sessionSetItem('shouldHideSmartBanner', true);
    dispatch(fetchBanner('top'));
    setShouldShowSmartBanner(false);
  };

  return (
    <div className={cx(styles.smartBanner, isRemoved ? styles.removed : null)}>
      <Close height="16px" width="16px" className={styles.close} onClick={removeBanner} />
      <RtGreenSquareLogo height="48px" width="48px" className={styles.icon} />
      <div className={styles.contentContainer}>
        <div className={styles.meta}>
          <span className={styles.title}>
            <Translate id="Top.Get_The_Rakuten_Travel_App" />
          </span>
          <p className={styles.storeName}>{isIOS ? 'App Store' : 'Google Play Store'}</p>
        </div>
        <Link url={appUrl} className={styles.viewLink} label={<ArrowRightThick size={16} />} />
      </div>
    </div>
  );
};

export default SmartBanner;
