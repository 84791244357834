import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import usePortal from 'react-useportal';

import { getCurrency, getLanguage, getMarket } from '@travel/i18n';
import { getRatParamsMap, PropMap } from '@travel/traveler-core/components/DataLayer/ratParamsMap';
import { convertRatPageLayout, useRat } from '@travel/traveler-core/components/DataLayer/utils';
import useDeviceType from '@travel/traveler-core/hooks/useDeviceType';
import { getRanData } from '@travel/traveler-core/store/ranStore/selectors';
import { RatObject, RatObjectProps } from '@travel/traveler-core/types/analytics';
import { ranDataToSendForConversation } from '@travel/traveler-core/utils/helper';
import { getCookie } from '@travel/utils';

import { setIsSSR, setPreviousPageName } from 'store/__router/actions';
import { getPreviousPageName } from 'store/__router/selectors';
import { getMember } from 'store/member/selectors';

import { useTealium } from 'hooks/useTealium';

import { getPreviousPurchase } from '../../utils/analytics';

const DATE_FORMAT = 'YYYY-MM-DD';

type CommonDataLayerProps = {
  pageName: string;
  siteSection: string;
  updateData?: string /* pass the changed data if params need to be updated on data change */;
};

export type DataLayerProps = CommonDataLayerProps & RatObjectProps;
type IndexedProps = {
  [K in keyof DataLayerProps]: DataLayerProps[K];
};
type Props = IndexedProps & {
  // Switches RAT call to asynchronous (100ms). Defaults to synchronous.
  isAsync?: boolean;
};

const DataLayer = (props: Props) => {
  const { isAsync = true, ...ratParams } = props;
  const dispatch = useDispatch();
  //Constant/Formatted param values
  const { isMember: isLogin, item } = useSelector(getMember);

  let hasPastPurchaseOnDevice: boolean = false;
  const pastPurchaseObj = getPreviousPurchase();

  const trackId: string | undefined = item?.trackId;
  const deviceId = getCookie('trv-device-id');
  const pastPurchaseKey = trackId || deviceId;
  if (pastPurchaseKey) {
    hasPastPurchaseOnDevice = Boolean(pastPurchaseObj[pastPurchaseKey]);
  }

  const ranData = useSelector(getRanData);
  const ranDataToSend = ranDataToSendForConversation(ranData);

  const marketCode = useSelector(getMarket)?.marketCode;
  const previousPageName = useSelector(getPreviousPageName);

  const ratPageLayout = useDeviceType();
  const ratLanguage = useSelector(getLanguage);
  const ratCurrencyCode = useSelector(getCurrency);
  const ratCountryCode = marketCode;

  const login_status = isLogin ? 'on' : 'off';
  const market_code = marketCode;
  const service_start_date = ratParams.checkIn ? dayjs(ratParams.checkIn).format(DATE_FORMAT) : '';
  const service_end_date = ratParams.checkOut ? dayjs(ratParams.checkOut).format(DATE_FORMAT) : '';
  const past_purchase_on_device = hasPastPurchaseOnDevice ? 'past purchase' : 'no past purchase';
  const previous_page_name = previousPageName !== ratParams.pageName && previousPageName;
  const device_id = deviceId;

  const login = isLogin && 1;
  const landing = !previousPageName && 1;
  const ranSiteID = ranDataToSend?.siteId;

  const ratObject: RatObject = {
    generic: {
      ratPageLayout: convertRatPageLayout(ratPageLayout),
      ratLanguage,
      ratCurrencyCode,
      ratCountryCode,
    },
    custom: {
      login_status,
      market_code,
      service_start_date,
      service_end_date,
      past_purchase_on_device,
      ...(previous_page_name
        ? {
            previous_page_name,
          }
        : {}),
      ...(device_id ? { device_id } : {}),
      ...(ranSiteID ? { affiliate_id: ranSiteID } : {}),
    },
    conversion: {
      ...(login ? { login } : {}),
      ...(landing ? { landing } : {}),
    },
  };

  const ratParamsMap = getRatParamsMap(ratObject);

  const { Portal } = usePortal();

  // action
  useRat(ratParams.updateData || ratParams.pageName, isAsync);

  useEffect(() => {
    ratParams.pageName && dispatch(setPreviousPageName(ratParams.pageName));
    dispatch(setIsSSR(false));
  }, [dispatch, ratParams.pageName]);

  const generateParamString = (paramObj: PropMap[]) => {
    return paramObj
      .map((param: PropMap) => {
        const paramValue = param.value ? param.value : ratParams[param.prop];
        return ratParams[param.prop] || param.value
          ? `'${param.id}':` + (typeof paramValue === 'string' ? `'${paramValue}'` : paramValue)
          : null;
      })
      .filter(params => params)
      .join(',');
  };

  useTealium(ratParams, ratObject, ratParams.updateData || ratParams.pageName);

  return (
    <Portal>
      {/* -- Generic Parameters -- */}
      {ratParamsMap.generic.map((genericParam: PropMap) =>
        ratParams[genericParam.prop] || genericParam.value ? (
          <input
            key={genericParam.id}
            type="hidden"
            name="rat"
            data-testid={`dataLayer-${genericParam.id}`}
            id={genericParam.id}
            value={genericParam.value ? genericParam.value : ratParams[genericParam.prop]}
          />
        ) : null,
      )}

      {/*-- Custom Parameters -- */}
      <input
        type="hidden"
        name="rat"
        id="ratCustomParameters"
        data-testid="dataLayer-ratCustomParameters"
        value={`{${generateParamString(ratParamsMap.custom)}}`}
      />

      {/*-- Conversion Parameters -- */}
      <input
        type="hidden"
        name="rat"
        id="ratCvEvent"
        data-testid="dataLayer-ratCvEvent"
        value={`{${generateParamString(ratParamsMap.conversion)}}`}
      />
    </Portal>
  );
};

export default DataLayer;
