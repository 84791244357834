import dayjs from 'dayjs';
import React, { Ref, useCallback, useImperativeHandle, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useLocaleWeekAndMonth } from '@travel/i18n';
import {
  DEFAULT_ADULT,
  DEFAULT_CHILDREN_AGES,
  DEFAULT_ROOMS,
} from '@travel/traveler-core/constants';
import { useTranslation } from '@travel/traveler-core/hooks';
import useDeviceType, { DESKTOP_SCREEN } from '@travel/traveler-core/hooks/useDeviceType';
import debounce from '@travel/traveler-core/utils/debounce';
import { toSEOSearchString } from '@travel/traveler-core/utils/seo';
import { DatePicker } from '@travel/ui';
import { Props as DateProps } from '@travel/ui/components/DatePicker';
import isNotEmptyArray from '@travel/ui/utils/arrays';
import { cx } from '@travel/utils/src/classNames';

import { AmountInputRef, AmountValue } from 'components/AmountInput';
import AmountInputWithRef from 'components/AmountInput/AmountInputWithRef';
import {
  InternalSalesPromotionFormQuery,
  SalesPromotionFormQuery,
} from 'components/SalesPromotionInput';

import { replaceLocation } from 'store/__router/actions';
import { getItem as getProviderInfo } from 'store/providerInformation/selectors';
import { clearErrorMessage } from 'store/vacancyCalendar/actions';
import { getErrors, getIsFetching } from 'store/vacancyCalendar/selectors';

import paths from 'core/universalRouter/paths';
import useEffectExceptForFirstRendering from 'hooks/useEffectExceptForFirstRendering';
import useFetchVacancyCalendarData from 'hooks/useFetchVacancyCalendarData';
import { RoomFormQuery } from 'ProviderList-Types';
import { getAllowedRedirectSearchQuery, removeDynamicQuery } from 'utils/search';

import PriceAndCoupons from './components/PriceAndCoupons';
import VacancyInfo from './components/VacancyInfo';

import styles from './vacancyCalendar.module.scss';

export type VacancyCalendarRef = {
  reset: () => void;
};

export type Props = DateProps & {
  className?: string;
  searchParam?: RoomFormQuery & SalesPromotionFormQuery & InternalSalesPromotionFormQuery;
  itemRatePlanId?: string;
  innerRef?: Ref<VacancyCalendarRef>;
};

function VacancyCalendar(props: Props) {
  const dispatch = useDispatch();
  const isPC = useDeviceType() === DESKTOP_SCREEN;
  const amountInputRef = useRef<AmountInputRef>(null);

  const providerInfo = useSelector(getProviderInfo);

  const isFetching = useSelector(getIsFetching);
  const errors = useSelector(getErrors);

  const hasErrors = isNotEmptyArray(errors) && errors[0];
  const currentMonth = dayjs(props.searchParam?.startDate).format('YYYY-MM');
  const nextMonth = dayjs(props.searchParam?.startDate)
    .add(1, 'month')
    .format('YYYY-MM');

  const [isCouponDialogOpen, setIsCouponDialogOpen] = useState(false);
  const { adults, childrenAges, rooms } = amountInputRef.current?.getAmount() || {
    adults: Number(props.searchParam?.adults) || DEFAULT_ADULT,
    rooms: Number(props.searchParam?.noOfUnits) || DEFAULT_ROOMS,
    childrenAges: props.searchParam?.childrenAges?.split(',').map(Number) || DEFAULT_CHILDREN_AGES,
  };

  const [startDate, setStartDate] = useState(props.searchParam?.startDate);
  const [endDate, setEndDate] = useState(props.searchParam?.endDate);
  const [months, setMonths] = useState([currentMonth, nextMonth]);

  const { weekdaysShort, monthsShort, firstDayOfWeek } = useLocaleWeekAndMonth();
  const clearLabelText = useTranslation({ id: 'Top.Modals.Calendar.Clear' });
  const {
    onClickFetchVacancyCalendar,
    onClickFetchVacancyCalendarPrice,
    onClickFetchMoreVacancyCalendar,
  } = useFetchVacancyCalendarData();

  const isDatesSet = !!(startDate && endDate);

  useImperativeHandle(props.innerRef, () => ({
    reset: () => {
      // reset amount input state
      amountInputRef.current?.setAmount({
        adults: Number(props.searchParam?.adults) || 1,
        childrenAges: props.searchParam?.childrenAges
          ? props.searchParam?.childrenAges?.split(',').map(Number)
          : [],
        rooms: Number(props.searchParam?.noOfUnits) || 1,
      });

      // reset selected date
      setStartDate(props.searchParam?.startDate);
      setEndDate(props.searchParam?.endDate);
    },
  }));

  // debounce to avoid continuous api call when user change conditions
  const debouncedFetch = useRef(
    debounce(
      (
        adults: number,
        children: number[],
        rooms: number,
        startDate: string,
        endDate: string,
        months: string[],
      ) => {
        onClickFetchVacancyCalendar(
          adults,
          children,
          rooms,
          startDate,
          endDate,
          months,
          props.itemRatePlanId,
          props.searchParam?.salesPromotionId,
        );
      },
      400,
    ),
  );

  const fetchApi = useCallback((...args) => {
    debouncedFetch.current(...args);
  }, []);

  // call api if any conditions changed (adults, children, rooms)
  const handleAmountChange = (newAmount: AmountValue) => {
    if (isPC) {
      fetchApi(
        newAmount.adults,
        newAmount.childrenAges,
        newAmount.rooms,
        startDate,
        endDate,
        months,
      );
    }
  };

  // call api once date range is selected
  useEffectExceptForFirstRendering(() => {
    if (props.startDate) {
      setStartDate(props.startDate);
      // case for re-select date range
      if (props.endDate === undefined) {
        setEndDate('');
      }
    }

    if (props.endDate) {
      setEndDate(props.endDate);
    }

    onClickFetchVacancyCalendarPrice(
      adults,
      childrenAges,
      rooms,
      props.startDate,
      props.endDate,
      props?.itemRatePlanId,
      props.searchParam?.salesPromotionId,
    );
  }, [props.startDate, props.endDate]);

  const onClickDayItem = (_event: React.MouseEvent | React.KeyboardEvent, day: dayjs.Dayjs) => {
    props.onSelectDate(_event, day);
  };

  const onClickNavigationCallBack = (months: string[]) => {
    // fetch more daily price when user clicks next / prev button on calendar
    setMonths(months);
    onClickFetchMoreVacancyCalendar(adults, childrenAges, rooms, months, props?.itemRatePlanId);
  };

  const onClickClear = () => {
    props.onClear();
    dispatch(clearErrorMessage());
    setStartDate('');
    setEndDate('');
  };

  const updateURL = useCallback(
    ({ search, hash }) => {
      // to update URL on query changed
      dispatch(
        replaceLocation({
          pathname: paths.providerInfo.pathResolver(providerInfo.id),
          search,
          hash,
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch],
  );

  const onClickUpdateUrl = () => {
    const formatChildrenAges = isNotEmptyArray(childrenAges) ? childrenAges.toString() : undefined;
    const url = new URL(window.location.toString());
    const activeTabId = url.searchParams.get('activeTabId');
    const listType = url.searchParams.get('listType');
    const newStartDate = startDate ? dayjs(startDate).format('YYYYMMDD') : '';
    const newEndDate = endDate ? dayjs(endDate).format('YYYYMMDD') : '';

    const allowedSearchParams = getAllowedRedirectSearchQuery(
      (props.searchParam as { [key: string]: string }) || {},
    );
    const filteredQueryParams = removeDynamicQuery(allowedSearchParams);

    updateURL({
      search: toSEOSearchString({
        ...filteredQueryParams,
        startDate: newStartDate,
        endDate: newEndDate,
        startServiceDate: newStartDate,
        endServiceDate: newEndDate,
        adults: adults,
        noOfUnits: rooms,
        childrenAges: formatChildrenAges,
        ...(activeTabId && { activeTabId: activeTabId }),
        ...(listType && { listType }),
      }),
      hash: props.itemRatePlanId,
    });
  };

  const onCloseClick = (
    rooms: number = DEFAULT_ROOMS,
    adults: number = DEFAULT_ADULT,
    childrenAges: number[] = DEFAULT_CHILDREN_AGES,
  ) => {
    onClickFetchVacancyCalendar(
      adults,
      childrenAges,
      rooms,
      startDate,
      endDate,
      months,
      props.itemRatePlanId,
      props.searchParam?.salesPromotionId,
    );
  };

  const onChangeCouponDialog = (state: boolean) => {
    setIsCouponDialogOpen(state);
  };

  const customPopupInfoBoxComponent = (
    <PriceAndCoupons
      rooms={rooms}
      adults={adults}
      childrenAges={childrenAges}
      startDate={startDate}
      endDate={endDate}
      onClick={onClickUpdateUrl}
      onChangeCouponDialog={onChangeCouponDialog}
    />
  );

  return (
    <DatePicker
      {...props}
      startDate={startDate}
      endDate={endDate}
      className={styles.popup}
      dialogClassName={styles.dialog}
      // localization
      weekdayLabels={weekdaysShort}
      monthLabels={monthsShort}
      weekType={firstDayOfWeek ? 'isoWeek' : 'week'}
      // translation
      clearLabelText={clearLabelText}
      PerDayComponent={VacancyInfo}
      onSelectDate={onClickDayItem}
      customPopupInfoBoxComponent={customPopupInfoBoxComponent}
      customPopupRightInfoBoxComponent={
        <AmountInputWithRef
          ref={amountInputRef}
          onPage={'vacancyCalendar'}
          dialogContentClassName={styles.dialogContent}
          defaultAdults={Number(props.searchParam?.adults)}
          defaultRooms={Number(props.searchParam?.noOfUnits)}
          defaultChildrenAges={
            props.searchParam?.childrenAges
              ? props.searchParam?.childrenAges.split(',').map(Number)
              : []
          }
          isDatesSet={isDatesSet}
          onCloseClick={onCloseClick}
          onValuesChange={handleAmountChange}
        />
      }
      onClickNavigationCallBack={onClickNavigationCallBack}
      onClear={onClickClear}
      dayWrapperClassName={cx(
        styles.slightSpaceAndRounding,
        styles.vacancyCalendarHighlight,
        hasErrors && styles.vacancyCalendarHighlightError,
      )}
      highlightType="full"
      customCalendarHeight={380}
      dialogProps={{
        ...props.dialogProps,
        customActionsComponent: customPopupInfoBoxComponent,
        buttonLabelPrimary: null,
      }}
      isLoading={isFetching}
      isClosingByOutSideClicking={!isCouponDialogOpen}
      dayItemClassName={styles.dayItem}
      calendarWrapperClassName={styles.calendarWrapper}
      hasAnimationDialog={true}
    />
  );
}

VacancyCalendar.defaultProps = {
  searchParam: {
    adults: 1,
    noOfUnits: 1,
    childrenAges: '',
  },
};

export default VacancyCalendar;
