import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { getLanguage } from '@travel/i18n';
import RanDataFormInputs from '@travel/traveler-core/components/RanDataFormInputs';
import { getRanData } from '@travel/traveler-core/store/ranStore/selectors';
import { cx } from '@travel/utils';

import SalesPromotionInput from 'components/SalesPromotionInput';
import { SearchFormWithRef } from 'components/SearchForm';
import { SubmitParams } from 'components/SearchForm/types';

import { Translate } from 'core/translate';
import usePlaceRedirect from 'hooks/usePlaceRedirect';
import { ProviderInfoQuery } from 'ProviderInformation-Types';
import { SearchFormQuery } from 'ProviderList-Types';
import { savedSearchStore } from 'utils/savedSearch';

import styles from './searchBar.module.scss';

type Props = {
  className?: string;
  query: SearchFormQuery | ProviderInfoQuery;
};

function SearchBar(props: Props) {
  const { className, query } = props;

  const placeRedirect = usePlaceRedirect();
  const language = useSelector(getLanguage);

  const [isOpenDatePicker, setIsOpenDatePicker] = useState(false);

  const ranData = useSelector(getRanData);

  const onSubmit = ({ place, formData }: SubmitParams) => {
    const searchQueryString = new URLSearchParams(formData as any).toString();
    const queryObj = Object.fromEntries(formData.entries());
    savedSearchStore(language, place, queryObj);
    placeRedirect(place, searchQueryString);
  };

  return (
    <div data-testid="top-searchForm-wrapper" className={cx(className, styles.wrapper)}>
      <h1 className={styles.titleWrapper}>
        <Translate id="Top.Tagline" className={styles.topLabel} />
      </h1>
      <SearchFormWithRef
        onPage="top"
        pageIdentifier=""
        isAutoOpenDatePicker={isOpenDatePicker}
        setIsAutoOpenDatePicker={setIsOpenDatePicker}
        query={query}
        translateId="Top.Search_Button"
        onSubmit={onSubmit}
        hiddenInput={
          <>
            <SalesPromotionInput {...query} />
            <RanDataFormInputs ranData={ranData} />
          </>
        }
      />
    </div>
  );
}

export default React.memo(SearchBar);
