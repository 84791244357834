import dayjs, { Dayjs } from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import React, { Ref, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';

import { L10nDate } from '@travel/i18n';
import { IconProps } from '@travel/icons';
import { Calendar } from '@travel/icons/ui';
import SimpleDateButton from '@travel/traveler-core/components/DateInput/SimpleDateButton';
import SmallDateButton from '@travel/traveler-core/components/DateInput/SmallDateButton';
import {
  DESKTOP_SCREEN,
  MOBILE_SCREEN,
  useBlurWrapper,
  useDeviceType,
  useKeyboard,
  useTranslation,
} from '@travel/traveler-core/hooks';
import { seoKeys } from '@travel/traveler-core/utils/seo';
import { DateButtonGroup, FlatButton, IconText } from '@travel/ui';
import { DisplayType, Props as DateProps } from '@travel/ui/components/DatePicker';
import { cx } from '@travel/utils';

import DataLayer from 'components/DataLayer';
import LocaleDatePicker from 'components/LocaleDatePicker';
import { VacancyCalendarRef } from 'components/VacancyCalendar';
import VacancyCalendarWithRef from 'components/VacancyCalendar/VacancyCalendarWithRef';

import { RAT_PAGE_TYPE_OBJ } from 'constants/ratAnalytics';
import { Translate } from 'core/translate';
import { RoomFormQuery } from 'ProviderList-Types';

import styles from './dateInput.module.scss';

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

export const DATE_FORM_FORMAT = 'YYYYMMDD';
type DateComponentType = { value: string; format?: string };
type SelectedRange = { start?: string; end?: string };

export type DateRef = {
  resetDate: () => void;
  focus: () => void;
} | null;

export type Props = {
  /** Custom style for wrapper */
  className?: string;
  /** String of date format to be displayed */
  displayFormat?: string;
  /** End date of selected range */
  endDate?: string;
  /** Date to define the first calendar */
  refDate?: string;
  /** Start date of selected range */
  startDate?: string;
  /** on what parent page this component lives */
  onPage:
    | 'top'
    | 'providerListDated'
    | 'providerListUndated'
    | 'providerInfoDated'
    | 'providerInfoUndated'
    | 'couponSearch'
    | 'area'
    | 'rateplan'
    | 'modification'
    | 'vacancyCalendar'
    | 'vacancyCalendarUndated';
  /** The callback function when ok button is clicked (on SP/TL) */
  onClickOkButton?: () => void;
  /** The callback function when the dialog was closed and state has been changed */
  onDateChanged?: (startDate?: string, endDate?: string) => void;
  /** Date to define the end of select day */
  refEndDate?: string;
  /** Whether date picker auto open after selected place */
  isAutoOpenDatePicker?: boolean;
  /** Set auto open date picker */
  setIsAutoOpenDatePicker?: (autoOpen: boolean) => void;
  /** inner Ref */
  innerRef?: Ref<DateRef>;
  /** Automatically open on mount */
  shouldOpenOnMount?: boolean;
  /** Callback for onClick start date */
  onClickStartDateCallBack?: () => void;
  /** searchParam from url */
  searchParam?: RoomFormQuery;
  /** itemRatePlanId from each card*/
  itemRatePlanId?: string;
  /** Custom props to be passed to VC */
  customDatePickerProps?: Partial<DateProps>;
  /** Callback for onMouseEnter */
  onDateButtonMouseEnter?: () => void;
  /** Callback for onMouseLeave */
  onDateButtonMouseLeave?: () => void;
  /** Flag to enable animation when Dialog mounted */
  hasAnimationDialog?: boolean;
};

const PROVIDER_LIST_ICON_PROPS: IconProps = { color: 'cilantro' };

function DateInput(props: Props) {
  const {
    displayFormat = 'M/D/YYYY',
    onClickStartDateCallBack,
    onClickOkButton,
    onDateChanged,
    searchParam,
    setIsAutoOpenDatePicker,
    shouldOpenOnMount,
    hasAnimationDialog,
  } = props;
  const deviceType = useDeviceType();
  const isSP = deviceType === MOBILE_SCREEN;
  const isPC = deviceType === DESKTOP_SCREEN;

  const referenceDate = dayjs(props.refDate).format(DATE_FORM_FORMAT);
  const referenceEndDate = props.refEndDate && dayjs(props.refEndDate).format(DATE_FORM_FORMAT);
  const DateComponent = (props: DateComponentType) => <L10nDate format="L" {...props} />;
  const DateComponentSmall = (props: DateComponentType) => <L10nDate format="MD" {...props} />;
  const NightsLabelComponent = (props: { noOfNights: number }) => (
    <Translate
      id="Traveler_Common.Calendar.Term.Nights"
      count={props.noOfNights}
      data={{ number_of_nights: props.noOfNights }}
    />
  );

  let mainElementRef = useRef<HTMLDivElement>(null);
  const [popupMinWidth, setPopupMinWidth] = useState<number | undefined>(undefined);

  // selected date range handler
  const [selectedRanged, setSelectedRange] = useState<SelectedRange>({
    start: props.startDate && dayjs(props.startDate).format(DATE_FORM_FORMAT),
    end: props.endDate && dayjs(props.endDate).format(DATE_FORM_FORMAT),
  });

  // button active state handler
  const isInvalidDate = !selectedRanged.start && !selectedRanged.end;

  // open dialog state handler
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(shouldOpenOnMount || false);
  const [selectedButton, setSelectedButton] = useState<'startDate' | 'endDate' | string>(
    'startDate',
  );
  const isSelectedCheckInButton = selectedButton === 'startDate';
  const startButtonRef = useRef<HTMLButtonElement | null>(null);
  const endButtonRef = useRef<HTMLButtonElement | null>(null);

  const currentFocus = useRef<'' | 'startDate' | 'endDate'>('');
  const previousFocus = useRef<'' | 'startDate' | 'endDate'>('');

  const { pageType, siteSection, parentName } = RAT_PAGE_TYPE_OBJ[props.onPage];

  const startDateLabel = useTranslation({ id: 'Top.Dates.Check_in' });
  const endDateLabel = useTranslation({ id: 'Top.Dates.Check_out' });

  const startDateButtonLabel = useTranslation({ id: 'Traveler_Common.Alt.Check_In_Date' });
  const endDateButtonLabel = useTranslation({ id: 'Traveler_Common.Alt.Check_Out_Date' });

  // page
  const isOnTopOrAreaPage = props.onPage === 'area' || props.onPage === 'top';
  const isProviderListPage =
    props.onPage === 'providerListUndated' || props.onPage === 'providerListDated';
  const isProviderInfoPage =
    props.onPage === 'providerInfoUndated' || props.onPage === 'providerInfoDated';
  const isProviderListOrInfoPage = isProviderInfoPage || isProviderListPage;
  const isCouponSearchPage = props.onPage === 'couponSearch';
  const isRatePlanItem = props.onPage === 'rateplan';
  const isModification = props.onPage === 'modification';
  const isVC = props.onPage === 'vacancyCalendar';
  const isVCUndated = props.onPage === 'vacancyCalendarUndated';

  // to verify the changes of dates
  let prevSelectedRanged = useRef<SelectedRange | null>(null);

  const datePickerRef = useRef<{
    contains: (node: Node) => boolean;
  } | null>(null);

  const vacancyCalendarRef = useRef<VacancyCalendarRef>(null);

  useImperativeHandle(props.innerRef, () => ({
    resetDate: () => {
      setSelectedButton('startDate');
      setSelectedRange({
        start: '',
        end: '',
      });
    },
    focus: () => {
      startButtonRef.current?.focus();
    },
  }));

  const verifyDateChanged = useCallback(() => {
    const { start, end } = selectedRanged;
    const { start: prevStart, end: prevEnd } = prevSelectedRanged.current || {};
    // reset value
    prevSelectedRanged.current = null;
    if (start !== prevStart || end !== prevEnd) {
      onDateChanged?.(start, end);
    }
  }, [onDateChanged, selectedRanged]);

  const onClickCloseDialogWithoutChange = useCallback(() => {
    setIsDatePickerOpen(false);
    setIsAutoOpenDatePicker?.(false);
    if (isVC || isVCUndated) {
      // reset init selectedRange for VacancyCalendar cases
      setSelectedRange({ start: searchParam?.startDate, end: searchParam?.endDate });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParam?.endDate, searchParam?.startDate, setIsAutoOpenDatePicker]);

  const onClickCloseDialog = useCallback(() => {
    onDateChanged && verifyDateChanged();
    setIsDatePickerOpen(false);
    setIsAutoOpenDatePicker?.(false);
  }, [onDateChanged, setIsAutoOpenDatePicker, verifyDateChanged]);

  const onClickStartDateButton = useCallback(() => {
    onClickStartDateCallBack?.();
    vacancyCalendarRef.current?.reset();
    setSelectedButton('startDate');
    setIsDatePickerOpen(true);
  }, [onClickStartDateCallBack]);

  // This is a fix for: TIDP-52442
  // TODO: After implementing TIDP-52764, it can be removed
  const onClickStartDateButtonVC = () => {
    if (isDatePickerOpen) {
      return;
    }
    onClickStartDateButton();
  };

  const onClickEndDateButton = useCallback(() => {
    setSelectedButton('endDate');
    setIsDatePickerOpen(true);
  }, []);

  const onSelectDateButton = useCallback(
    (_event: React.MouseEvent, button: 'startDate' | 'endDate' | string) => {
      setSelectedButton(button);
    },
    [],
  );

  const onFocusDateButton = useCallback(
    (_event: React.FocusEvent, button: 'startDate' | 'endDate' | string) => {
      previousFocus.current = currentFocus.current;
      currentFocus.current = button as 'startDate' | 'endDate' | '';
    },
    [],
  );

  useEffect(() => {
    if (shouldOpenOnMount) {
      startButtonRef.current?.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.isAutoOpenDatePicker) {
      setIsDatePickerOpen(true);
    }
  }, [props.isAutoOpenDatePicker]);

  const onSelectDate = useCallback(
    (_event: React.MouseEvent | React.KeyboardEvent, day: Dayjs) => {
      // set selected range state
      setSelectedRange(prevState => {
        setIsAutoOpenDatePicker?.(false);
        const { start, end } = prevState;
        const formattedDate = day.format(DATE_FORM_FORMAT);
        if (isSelectedCheckInButton) {
          // if the check-in button is activate
          // ; when the given start date is same or after the selected end date,
          // then reset the end date and activate the check-out button
          if (end && day.isSameOrAfter(dayjs(end))) {
            setSelectedButton('endDate');
            return { start: formattedDate, end: undefined };
          }
          // ; when the selected end date is not define, then activate the check-out button
          if (!end) {
            setSelectedButton('endDate');
          }
          // ; when the given start date is before the selected end date, then use it as selected start date
          return { start: formattedDate, end };
        } else {
          // if the check-out button is activate
          // ; when the given end date is same or before the selected start date, then reset the end date
          // ; and use it as selected start date
          if (start && day.isSameOrBefore(dayjs(start))) {
            return { start: formattedDate, end: undefined };
          }
          // ; when the selected start date is not define, then activate the check-in button
          if (!start) {
            setSelectedButton('startDate');
          }
          // ; when the given end date is after the selected start date, then use it as selected end date
          return { start, end: formattedDate };
        }
      });
    },
    [isSelectedCheckInButton, setIsAutoOpenDatePicker],
  );

  const onSelectDateVC = useCallback(
    (_event: React.MouseEvent | React.KeyboardEvent, day: Dayjs) => {
      if (isSP) {
        // Use the same logic as onSelectDate because there are indication
        return onSelectDate(_event, day);
      } else {
        // there is no indication about if it is check in date or check out date, thus need another simplified logic
        setSelectedRange(prevState => {
          setIsAutoOpenDatePicker?.(false);
          const { start, end } = prevState;
          const formattedDate = day.format(DATE_FORM_FORMAT);
          if (start) {
            if (!end) {
              // user only choose start date, need to choose end date now
              // if chosen end date is before start date, make it as a new start day
              if (day.isSameOrBefore(start)) {
                return { start: formattedDate, end: undefined };
              }
              // set the new chosen date as end date
              return { start, end: formattedDate };
            } else {
              // user already set start and end, initialize a new search
              return { start: formattedDate, end: undefined };
            }
          } else {
            // a new search
            return { start: formattedDate, end: undefined };
          }
        });
      }
    },
    [isSP, onSelectDate, setIsAutoOpenDatePicker],
  );

  const onKeyDown = useKeyboard(
    React.useMemo(() => {
      return {
        Escape: (event: React.KeyboardEvent) => {
          if (isDatePickerOpen) {
            event.stopPropagation();
            setIsDatePickerOpen(false);
            if (
              document.activeElement !== startButtonRef.current &&
              document.activeElement !== endButtonRef.current
            ) {
              selectedButton === 'endDate'
                ? endButtonRef.current?.focus()
                : startButtonRef.current?.focus();
            }
          }
        },
      };
    }, [selectedButton, isDatePickerOpen]),
  );

  const onKeyUp = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === 'Tab') {
        if (!isDatePickerOpen) {
          if (currentFocus.current === 'startDate' && previousFocus.current !== 'endDate') {
            setIsDatePickerOpen(true);
            setSelectedButton('startDate');
          } else if (currentFocus.current === 'endDate' && previousFocus.current !== 'startDate') {
            setIsDatePickerOpen(true);
            setSelectedButton('endDate');
          }
        }
      }
    },
    [isDatePickerOpen],
  );

  const onBlurHandler = React.useCallback(
    (event: React.FocusEvent) => {
      currentFocus.current = '';
      previousFocus.current = '';
      if (isPC && !datePickerRef.current?.contains(event.relatedTarget as Node)) {
        setIsDatePickerOpen(false);
      }
    },
    [isPC],
  );

  const onBlur = useBlurWrapper(onBlurHandler);

  const onFocusStartDateButton = React.useCallback(() => {
    previousFocus.current = currentFocus.current;
    currentFocus.current = 'startDate';
  }, []);
  const onFocusEndDateButton = React.useCallback(() => {
    previousFocus.current = currentFocus.current;
    currentFocus.current = 'endDate';
  }, []);

  // to update state when prop has been changed
  useEffect(() => {
    setSelectedRange({
      start: props.startDate && dayjs(props.startDate).format(DATE_FORM_FORMAT),
      end: props.endDate && dayjs(props.endDate).format(DATE_FORM_FORMAT),
    });
  }, [props.startDate, props.endDate]);

  // to store the prev state to verify if there is any changes
  useEffect(() => {
    if (onDateChanged && !prevSelectedRanged.current && isDatePickerOpen) {
      prevSelectedRanged.current = selectedRanged;
    }
  }, [isDatePickerOpen, onDateChanged, selectedRanged]);

  // calculate the main element position to show the date-picker dialog (PC)
  useEffect(() => {
    if (isPC && isDatePickerOpen && mainElementRef.current) {
      const mainElementRect = mainElementRef.current.getBoundingClientRect();
      setPopupMinWidth(mainElementRect.width);
    }
  }, [isPC, isDatePickerOpen]);

  // To close the dialog when both dates are set
  useEffect(() => {
    let closeDialogTimer: NodeJS.Timeout;
    if (selectedRanged.start && selectedRanged.end && isPC && !props.isAutoOpenDatePicker) {
      if (isVC || isVCUndated) {
        // we don't want to automatically submit and close dialog after selected date on VacancyCalendar
      } else {
        // XXX: This delay is to give user a glance of his selected date
        closeDialogTimer = setTimeout(() => onClickCloseDialog(), 600);
      }
    }

    return () => {
      clearTimeout(closeDialogTimer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRanged, onClickCloseDialog, isPC, props.isAutoOpenDatePicker]);

  // default props
  const defaultSelectedDateProps = {
    DateComponent: DateComponent,
    startDateLabel,
    endDateLabel,
    startDate: selectedRanged.start && dayjs(selectedRanged.start).format(displayFormat),
    endDate: selectedRanged.end && dayjs(selectedRanged.end).format(displayFormat),
  };

  const defaultDateButtonGroupProps = {
    selectedButton,
    onClickStartDateButton,
    onClickEndDateButton,
    onFocusStartDateButton,
    onFocusEndDateButton,
    isShowSelectedState: isDatePickerOpen,
  };

  // custom DatePicker props based on device
  let customDatePickerProps;

  const onClearDatePicker = useCallback(() => {
    setSelectedRange({ start: '', end: '' });
    setSelectedButton('startDate');
  }, []);

  // will be used for mobile/tablet date-picker only
  const onClickPrimary = useCallback(() => {
    onClickCloseDialog();
    onClickOkButton?.();
  }, [onClickCloseDialog, onClickOkButton]);

  if (!isPC) {
    const nights =
      selectedRanged.start && selectedRanged.end
        ? dayjs(selectedRanged.end).diff(selectedRanged.start, 'day')
        : 0;
    customDatePickerProps = {
      // 2 Years included current month
      numberOfMonths: 25,
      type: 'dialog' as DisplayType,
      dialogProps: {
        buttonLabelPrimary: (
          <>
            {isProviderInfoPage ? (
              <Translate id="Provider_Info.Update" />
            ) : (
              <Translate id="Traveler_Common.OK" />
            )}
            {selectedRanged.start && selectedRanged.end && (
              <>
                &nbsp;
                <Translate
                  id="Traveler_Common.Calendar.Term.Nights"
                  data={{
                    number_of_nights: nights,
                  }}
                  count={nights}
                />
              </>
            )}
          </>
        ),
        onClickPrimary,
      },
    };
  } else {
    customDatePickerProps = {
      numberOfMonths: 2,
      type: 'popup' as DisplayType,
      popupProps: { anchorEl: mainElementRef.current, minWidth: popupMinWidth },
    };
  }

  customDatePickerProps = {
    ...customDatePickerProps,
    ...props.customDatePickerProps,
  };

  // form value
  let formCheckInDate = selectedRanged.start;
  let formCheckOutDate = selectedRanged.end;

  if (formCheckInDate && !formCheckOutDate) {
    formCheckOutDate = dayjs(formCheckInDate)
      .add(1, 'day')
      .format(DATE_FORM_FORMAT);
  }

  if (!formCheckInDate && formCheckOutDate) {
    formCheckInDate = dayjs(referenceDate)
      .add(1, 'day')
      .format(DATE_FORM_FORMAT);
  }

  // to handle the case that user select checkout date as today
  if (dayjs(formCheckInDate).isAfter(dayjs(formCheckOutDate))) {
    formCheckInDate = dayjs(formCheckOutDate).format(DATE_FORM_FORMAT);
    formCheckOutDate = dayjs(formCheckInDate)
      .add(1, 'day')
      .format(DATE_FORM_FORMAT);
  }

  return (
    <div
      role="toolbar"
      onKeyDown={onKeyDown}
      className={props.className}
      ref={mainElementRef}
      data-testid="dateInput-wrapper"
      onBlur={onBlur}
      onKeyUp={onKeyUp}
      tabIndex={0}
    >
      {formCheckInDate && (
        <input
          type="hidden"
          data-testid="dateInput-startDate-hiddenInput"
          name={seoKeys.startDate}
          value={formCheckInDate}
        />
      )}
      {formCheckOutDate && (
        <input
          type="hidden"
          data-testid="dateInput-endDate-hiddenInput"
          name={seoKeys.endDate}
          value={formCheckOutDate}
        />
      )}
      {isSP && isOnTopOrAreaPage && (
        <SmallDateButton
          className={styles.topAreaSmallButton}
          startDate={formCheckInDate}
          endDate={formCheckOutDate}
          onClick={onClickStartDateButton}
          onFocus={onFocusStartDateButton}
          DateComponent={DateComponentSmall}
          buttonRef={startButtonRef}
          ariaLabel={startDateButtonLabel}
        />
      )}
      {!isSP && isOnTopOrAreaPage && (
        <DateButtonGroup
          className={cx(styles.topAreaButtonGroup, isDatePickerOpen && styles.activeBorder)}
          buttonClassName={styles.topPageButton}
          unhoveredColor="default"
          hoveredColor="default"
          startButtonRef={startButtonRef}
          endButtonRef={endButtonRef}
          {...defaultDateButtonGroupProps}
          {...defaultSelectedDateProps}
        />
      )}
      {!isPC && isProviderListOrInfoPage && (
        <SimpleDateButton
          className={cx(
            styles.providerListButton,
            isProviderInfoPage && styles.providerInfoDateButton,
          )}
          startDate={formCheckInDate}
          endDate={formCheckOutDate}
          onClick={onClickStartDateButton}
          onFocus={onFocusStartDateButton}
          DateComponent={DateComponent}
          buttonRef={startButtonRef}
          ariaLabel={startDateButtonLabel}
        />
      )}
      {isPC && isProviderListOrInfoPage && (
        <DateButtonGroup
          className={cx(
            styles.providerListButtonGroup,
            isDatePickerOpen && styles.activeBorder,
            isProviderInfoPage && styles.providerInfoDateButton,
          )}
          buttonClassName={cx(styles.providerListButton, isInvalidDate && styles.placeholderColor)}
          buttonIconProps={PROVIDER_LIST_ICON_PROPS}
          unhoveredColor="default"
          hoveredColor="default"
          startButtonRef={startButtonRef}
          endButtonRef={endButtonRef}
          startButtonAriaLabel={startDateButtonLabel}
          endButtonAriaLabel={endDateButtonLabel}
          {...defaultDateButtonGroupProps}
          {...defaultSelectedDateProps}
        />
      )}
      {isCouponSearchPage && (
        <>
          {isSP ? (
            <SimpleDateButton
              className={cx(styles.couponSearchSimpleButton, styles.couponSearchButtonIcon)}
              iconClassName={styles.couponButtonIcon}
              startDate={formCheckInDate}
              endDate={formCheckOutDate}
              customDatelessText={<Translate id="Top.Search.Date_Selection" />}
              iconSize={16}
              onClick={onClickStartDateButton}
              onFocus={onFocusStartDateButton}
              DateComponent={DateComponent}
              buttonRef={startButtonRef}
              ariaLabel={startDateButtonLabel}
            />
          ) : (
            <DateButtonGroup
              className={cx(styles.topPageButtonGroup, styles.couponSearchButtonIcon)}
              buttonClassName={styles.topPageButton}
              startButtonRef={startButtonRef}
              endButtonRef={endButtonRef}
              startButtonAriaLabel={startDateButtonLabel}
              endButtonAriaLabel={endDateButtonLabel}
              {...defaultDateButtonGroupProps}
              {...defaultSelectedDateProps}
            />
          )}
        </>
      )}
      {isRatePlanItem && (
        <FlatButton
          data-testid="dateInput-chooseDate-button"
          onClick={onClickStartDateButton}
          onFocus={onFocusStartDateButton}
          isSmallerButtonByDefault={true}
          data-button="startDate"
          innerRef={startButtonRef}
          ariaLabel={startDateButtonLabel}
        >
          <Translate id="Provider_Info.Room_List.Plan_Info.Choose_Date" />
        </FlatButton>
      )}

      {isVCUndated && (
        <FlatButton
          data-testid="dateInput-checkAvailability-button"
          ariaLabel="Check availability"
          onClick={onClickStartDateButtonVC}
          isSmallerButtonByDefault={true}
        >
          <Translate id="Provider_Info.Plan_List.Room_Info.Check_Availability" />
        </FlatButton>
      )}

      {isVC ? (
        !isSP ? (
          <FlatButton
            data-testid="dateInput-checkAvailability-button"
            ariaLabel="Check availability"
            onClick={onClickStartDateButtonVC}
            isSmallerButtonByDefault={true}
            classType="secondary"
            className={styles.vcWrapper}
            onMouseEnter={props?.onDateButtonMouseEnter}
            onMouseLeave={props?.onDateButtonMouseLeave}
          >
            <Calendar size={16} color="cilantro" />
          </FlatButton>
        ) : (
          <IconText
            data-testid="dateInput-checkAvailability-button"
            text={<Translate id="Provider_Info.Plan_List.Room_Info.Check_Availability" />}
            icon={<Calendar size={16} color="cilantro" />}
            className={styles.availableButton}
            spaceBetween={8}
            onClick={onClickStartDateButton}
          />
        )
      ) : null}
      {isModification && (
        <DateButtonGroup
          className={styles.modificationButtonGroup}
          buttonClassName={styles.modificationPageButton}
          startButtonRef={startButtonRef}
          endButtonRef={endButtonRef}
          startButtonAriaLabel={startDateButtonLabel}
          endButtonAriaLabel={endDateButtonLabel}
          unhoveredColor="default"
          hoveredColor="default"
          {...defaultDateButtonGroupProps}
          {...defaultSelectedDateProps}
        />
      )}
      {(isVC || isVCUndated) && isDatePickerOpen ? (
        <VacancyCalendarWithRef
          ref={vacancyCalendarRef}
          className={styles[`picker--${props.onPage}`]}
          selectedButton={selectedButton}
          isOpen={isDatePickerOpen}
          onSelectDate={onSelectDateVC}
          referenceDate={referenceDate}
          referenceEndDate={referenceEndDate}
          onSelectButton={onSelectDateButton}
          onClose={onClickCloseDialogWithoutChange}
          onClear={onClearDatePicker}
          isDisplayedOnDialog={isCouponSearchPage}
          NightsLabelComponent={NightsLabelComponent}
          shouldShowCurrentDate={true}
          contentRef={datePickerRef}
          searchParam={searchParam}
          itemRatePlanId={props.itemRatePlanId}
          {...defaultSelectedDateProps}
          {...customDatePickerProps}
        />
      ) : (
        <LocaleDatePicker
          className={styles[`picker--${props.onPage}`]}
          selectedButton={selectedButton}
          isOpen={isDatePickerOpen}
          onSelectDate={onSelectDate}
          referenceDate={referenceDate}
          referenceEndDate={referenceEndDate}
          onSelectButton={onSelectDateButton}
          onFocusButton={onFocusDateButton}
          onClose={onClickCloseDialog}
          onClear={onClearDatePicker}
          isDisplayedOnDialog={isCouponSearchPage}
          NightsLabelComponent={NightsLabelComponent}
          shouldShowCurrentDate={true}
          contentRef={datePickerRef}
          hasAnimationDialog={hasAnimationDialog}
          {...defaultSelectedDateProps}
          {...customDatePickerProps}
        />
      )}
      {isDatePickerOpen && !isPC && (
        <DataLayer
          pageName={`${parentName}:dates_calendar_popup`}
          pageType={pageType}
          siteSection={siteSection}
        />
      )}
    </div>
  );
}

export default DateInput;
