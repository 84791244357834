import { seoKeys } from '@travel/traveler-core/utils/seo';

export type QueryObject = { [key: string]: string };

const trimSearchQueryKey = (key: string) => key.replace(/_.*}}/, '');

const ALLOWED_REDIRECT_QUERY_KEYS = [
  seoKeys.startDate,
  seoKeys.endDate,
  seoKeys.noOfUnits,
  seoKeys.adults,
  seoKeys.childAge,
  seoKeys.childNo,
  seoKeys.salesPromotionId,
  seoKeys.sortKey,
  // custom navigation from coupon search
  'isCouponSpecified',
  'features',
  'minPrice',
  'maxPrice',
  'starRatings',
  'freeCancellation',
  // provider info specific queries
  'listType',
  'activeTabId',
].map(trimSearchQueryKey);

const BASIC_QUERY_KEYS = [
  seoKeys.startDate,
  seoKeys.endDate,
  seoKeys.noOfUnits,
  seoKeys.adults,
  seoKeys.childAge,
  seoKeys.childNo,
  seoKeys.salesPromotionId,
  'isCouponSpecified',
].map(trimSearchQueryKey);

const REMOVED_DYNAMIC_QUERY_KEYS = [seoKeys.adults, seoKeys.childAge, seoKeys.childNo].map(
  trimSearchQueryKey,
);

const removedQueryRegex = new RegExp(REMOVED_DYNAMIC_QUERY_KEYS.join('|'));

export const getAllowedRedirectSearchQuery = (queryObject: QueryObject) => {
  return Object.entries(queryObject).reduce((result, [key, value]) => {
    if (!ALLOWED_REDIRECT_QUERY_KEYS.includes(key.replace(/_.*/, ''))) {
      return result;
    }
    return { ...result, [key]: value };
  }, {});
};

export const getBasicRedirectSearchQuery = (queryObject: QueryObject) => {
  return Object.entries(queryObject).reduce((result, [key, value]) => {
    if (!BASIC_QUERY_KEYS.includes(key.replace(/_.*/, ''))) {
      return result;
    }
    return { ...result, [key]: value };
  }, {});
};

export const removeDynamicQuery = (queryObject: QueryObject) => {
  return Object.entries(queryObject).reduce((result, [key, value]) => {
    if (removedQueryRegex.test(key)) {
      return result;
    }
    return { ...result, [key]: value };
  }, {});
};
