import dayjs from 'dayjs';
import React, { useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';

import { useDatesChange } from '@travel/traveler-core/hooks/useDatesChange';
import { useDeviceTypes } from '@travel/traveler-core/hooks/useDeviceType';
import { FlatButton } from '@travel/ui';
import { cx } from '@travel/utils';

import { AmountInputRef } from 'components/AmountInput';
import AmountInputWithRef from 'components/AmountInput/AmountInputWithRef';
import { DateRef } from 'components/DateInput';
import DateInputWithRef from 'components/DateInput/DateInputWithRef';
import Form from 'components/Form';
import PlaceInput from 'components/PlaceInput';

import { autoFilledCheckInDate, autoFilledCheckOutDate } from 'constants/date';
import { EMPTY_ARRAY, EMPTY_FUNCTION } from 'constants/defaultValue';
import { Translate } from 'core/translate';
import useDialogHandler from 'hooks/useDialogHandler';
import { SuggestionsItem } from 'Suggestions-Types';

import { SearchFormProps, SearchFormRef } from './types';

import styles from './searchForm.module.scss';

const DATE_FORMAT = 'YYYYMMDD';

function SearchForm(props: SearchFormProps) {
  const {
    onPage,
    currentPlaceName = '',
    defaultText = '',
    wrapperClassName,
    searchFormClassName,
    pageIdentifier,
    query,
    translateId,
    innerRef,
    isAutoOpenDatePicker,
    setIsAutoOpenDatePicker,
    shouldSubmitOnInputChange,
    shouldOpenDatePickerOnMount,
    shouldOpenDatePickerOnSelect = true,
    shouldSaveSearch = true,
    onSubmit: handleSubmit,
    expandPanelClassName,
    filterForm,
    hiddenInput,
    shouldAutoFillDate,
  } = props;

  let initialPlace = {} as SuggestionsItem;

  const isOnTopOrArea = onPage === 'top' || onPage === 'area';

  if (!isOnTopOrArea) {
    initialPlace = {
      id: pageIdentifier,
      pathId: pageIdentifier,
      type: 'SPOT',
      name: currentPlaceName,
      category:
        onPage === 'providerListDated' || onPage === 'providerListUndated'
          ? 'POINT_OF_INTEREST'
          : 'PROVIDER',
    } as SuggestionsItem;
  }

  useEffect(() => {
    setCurrentPlace(currentPlace => ({
      ...currentPlace,
      name: currentPlaceName,
    }));
  }, [currentPlaceName]);

  const [currentPlace, setCurrentPlace] = useState<SuggestionsItem>(initialPlace);
  const [isError, setIsError] = useState(false);
  const [startDate, setStartDate] = useState(query.startDate || '');
  const [endDate, setEndDate] = useState(query.endDate || '');
  // show loading button before place redirect
  const [isLoading, setIsLoading] = useState(false);
  const { isSP } = useDeviceTypes();
  // for provider list page and provider info page sp search panel
  const {
    isOpen: isExpandPanel,
    onToggle: onToggleSearchPanel,
    onClose: onCloseSearchPanel,
  } = useDialogHandler(isOnTopOrArea ? true : false);

  const formEl = useRef<HTMLFormElement | null>(null);
  const dateRef = useRef<DateRef>(null);
  const amountInputRef = useRef<AmountInputRef>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const { isDatesSet, onDatesSet } = useDatesChange(
    Boolean(query?.startDate) && Boolean(query?.endDate),
  );

  const resetDates = useCallback(() => {
    dateRef.current?.resetDate();
    setStartDate('');
    setEndDate('');
    onDatesSet('', '');
  }, [onDatesSet]);

  const getStartDate = () => {
    if (startDate) {
      return startDate;
    }
    if (shouldAutoFillDate) {
      return autoFilledCheckInDate.format(DATE_FORMAT);
    }
    return '';
  };

  const getEndDate = () => {
    if (endDate) {
      return endDate;
    }
    if (shouldAutoFillDate) {
      return autoFilledCheckOutDate.format(DATE_FORMAT);
    }
    return '';
  };

  const onSubmit = useCallback(
    (place: SuggestionsItem, shouldResetFilter?: boolean) => {
      if (!formEl.current) {
        return;
      }
      if (!place.pathId) {
        setIsError(true);
        return;
      }
      const formData = new FormData(formEl.current);
      handleSubmit({ place, shouldResetFilter, formData });
    },
    [handleSubmit],
  );

  useImperativeHandle(innerRef, () => ({
    onSubmit: () => {
      onSubmit(currentPlace, false);
    },
  }));

  const onSubmitOnPlaceChanged = useCallback((place: SuggestionsItem) => onSubmit(place, true), [
    onSubmit,
  ]);

  const onInputFieldButtonClick = useCallback(() => {
    if (shouldSubmitOnInputChange) {
      onSubmit(currentPlace, false);
    }
  }, [currentPlace, onSubmit, shouldSubmitOnInputChange]);

  const onSubmitForm = useCallback(() => {
    setIsLoading(true);
    onSubmit(currentPlace, true);
  }, [currentPlace, onSubmit]);

  const onChangePlace = useCallback(
    (place: SuggestionsItem) => {
      setCurrentPlace(place);
      if (place.pathId) {
        setIsError(false);
      } else {
        if (isSP && !place.name) {
          setIsError(true);
        }
      }
      if (shouldSaveSearch) {
        if (place.isSavedSearch && place.query) {
          // setDate
          const startDate = place.query.startDate?.toString();
          const endDate = place.query.endDate?.toString();
          const hasDates = Boolean(startDate && endDate);
          const isStartDateBeforeToday = dayjs(startDate).isSameOrBefore(
            dayjs().subtract(1, 'day'),
          );
          // the dates needs to be set for the childrenAges to be set
          const shouldSetAmount = (hasDates && !isStartDateBeforeToday) || !hasDates;
          if (startDate && endDate) {
            if (isStartDateBeforeToday) {
              resetDates();
              if (isOnTopOrArea || !isSP) {
                // when sp, only open date picker when it's top or area
                setIsAutoOpenDatePicker?.(true);
                dateRef.current?.focus();
              }
            } else {
              setStartDate(startDate);
              setEndDate(endDate);
              onDatesSet(startDate, endDate);
            }
          }
          // set rooms/guests/children amount
          if (shouldSetAmount) {
            amountInputRef.current?.setAmount({
              rooms: place.query.noOfUnits ? Number(place.query.noOfUnits) : undefined,
              adults: place.query.adults,
              childrenAges: place.query.childrenAges,
            });
          }
          if (startDate && endDate && shouldSetAmount) {
            buttonRef.current?.focus();
          }
        }
      }

      if (isSP && shouldSubmitOnInputChange && place.pathId) {
        onSubmitOnPlaceChanged(place);
      }
    },
    [
      isOnTopOrArea,
      isSP,
      onDatesSet,
      onSubmitOnPlaceChanged,
      resetDates,
      setIsAutoOpenDatePicker,
      shouldSaveSearch,
      shouldSubmitOnInputChange,
    ],
  );

  const onSelectPlace = useCallback(
    (isSavedSearch?: boolean, hasDate?: boolean, isFromBlur?: boolean) => {
      if (shouldSaveSearch) {
        if (isSavedSearch && !hasDate && isDatesSet) {
          resetDates();
        } else if (!isSavedSearch && !isDatesSet && shouldOpenDatePickerOnSelect) {
          if ((isOnTopOrArea || !isSP) && !isFromBlur) {
            dateRef.current?.focus();
            setIsAutoOpenDatePicker(true);
          }
        }
      } else {
        // area page
        !isDatesSet && setIsAutoOpenDatePicker(true);
      }
    },
    [
      isDatesSet,
      isOnTopOrArea,
      isSP,
      resetDates,
      setIsAutoOpenDatePicker,
      shouldOpenDatePickerOnSelect,
      shouldSaveSearch,
    ],
  );

  const onDeletePlace = useCallback(() => setCurrentPlace({} as SuggestionsItem), [
    setCurrentPlace,
  ]);

  const placeInput = (
    <div className={styles.placeInput}>
      <PlaceInput
        onPage={onPage}
        defaultText={props.destinationTextLabel ? '' : defaultText || currentPlace.name}
        onChangePlace={onChangePlace}
        destinationTextLabel={props.destinationTextLabel}
        pathIdScope={onPage === 'area' ? pageIdentifier : ''}
        onSelectPlace={onSelectPlace}
        onDeletePlace={onDeletePlace}
        hasError={isError}
        errorMessage={
          <Translate
            id="Traveler_Input_Error.Global_Search.Destination_Search.No_Input"
            className={styles.errorMessage}
          />
        }
        errorMessageClassName={styles.errorMessageWrapper}
        hasAnimation={true}
      />
    </div>
  );

  const dateInput = (
    <DateInputWithRef
      ref={dateRef}
      className={cx(styles.dateInput, !isOnTopOrArea && styles.providerDateInput)}
      onPage={onPage}
      shouldOpenOnMount={!startDate && !endDate && shouldOpenDatePickerOnMount}
      startDate={getStartDate()}
      endDate={getEndDate()}
      onClickOkButton={onInputFieldButtonClick}
      onDateChanged={onDatesSet}
      isAutoOpenDatePicker={isAutoOpenDatePicker}
      setIsAutoOpenDatePicker={setIsAutoOpenDatePicker}
      hasAnimationDialog={true}
    />
  );

  const amountInput = (
    <AmountInputWithRef
      ref={amountInputRef}
      onClickOkButton={onInputFieldButtonClick}
      className={styles.amountInput}
      onPage={onPage}
      defaultAdults={Number(query.adults)}
      defaultRooms={Number(query.noOfUnits)}
      defaultChildrenAges={
        query.childrenAges ? query.childrenAges.split(',').map(Number) : EMPTY_ARRAY
      }
      isDatesSet={isDatesSet}
      onCloseClick={onInputFieldButtonClick}
      hasAnimationDialog={true}
    />
  );

  const toggleButton =
    props.toggleButton &&
    props.toggleButton({
      query,
      onClick: onToggleSearchPanel,
      text: currentPlace.name,
      isExpandPanel: isExpandPanel,
    });

  return (
    <>
      <Form
        ref={formEl}
        className={wrapperClassName}
        onSubmit={onSubmitForm}
        data-testid="searchForm-wrapper"
      >
        {!isSP && (
          <>
            <div
              className={cx(
                styles.searchForm,
                isError && styles.hasError,
                isOnTopOrArea && styles.topAreaSearchForm,
              )}
            >
              {placeInput}
              {dateInput}
              {amountInput}
              <FlatButton
                className={cx(styles.submitButton, isOnTopOrArea && styles.topAreaSubmitButton)}
                type="submit"
                data-testid="searchForm-submitButton"
                innerRef={buttonRef}
              >
                <Translate id={translateId} />
              </FlatButton>
            </div>
          </>
        )}
        {isSP && (
          <>
            <div
              className={cx(
                searchFormClassName,
                styles.searchForm,
                isError && styles.hasError,
                isOnTopOrArea && styles.topAreaSearchForm,
                isExpandPanel && expandPanelClassName,
              )}
              data-testid="searchForm-expandPanel-wrapper"
            >
              {toggleButton}
              <div translate="no">
                {placeInput}
                {dateInput}
                {amountInput}
                {isOnTopOrArea && (
                  <FlatButton
                    className={cx(styles.submitButton, isOnTopOrArea && styles.topAreaSubmitButton)}
                    type="submit"
                    data-testid="searchForm-submitButton"
                    innerRef={buttonRef}
                    isLoading={isLoading}
                  >
                    <Translate id={translateId} />
                  </FlatButton>
                )}
              </div>
            </div>
          </>
        )}
        {filterForm}
        {hiddenInput}
      </Form>
      {isExpandPanel && props.toggleButton && isSP && (
        <button
          tabIndex={-1}
          className={styles.overlay}
          onClick={onCloseSearchPanel}
          onKeyDown={EMPTY_FUNCTION}
        />
      )}
    </>
  );
}

export const SearchFormWithRef = React.memo(
  React.forwardRef((props: SearchFormProps, ref: React.Ref<SearchFormRef>) => {
    return <SearchForm {...props} innerRef={ref} />;
  }),
);

export default React.memo(SearchForm);
