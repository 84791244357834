/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { RatObject } from '@travel/traveler-core/types/analytics';

import { resetDataLayer, setDataLayer } from 'store/seo/actions';

import { ratPropsToParams } from 'constants/ratAnalytics';
import { callTealium, convertRatToTealium } from 'utils/dataLayer';

import { DataLayerProps } from '../components/DataLayer';

export const useTealium = (props: DataLayerProps, ratObject: RatObject, shouldCallStr?: string) => {
  const dispatch = useDispatch();
  const landing = useRef(false);

  useEffect(() => {
    //document.referrer does not work in localhost
    if (typeof window !== 'undefined') {
      landing.current = document.referrer !== '';
    }
  }, []);

  const updateOn = props.updateData || props.pageName;

  useEffect(() => {
    if (shouldCallStr) {
      const tealiumObject = convertRatToTealium(ratPropsToParams, props, ratObject);

      dispatch(setDataLayer(props));
      callTealium(tealiumObject);
    }

    return () => {
      dispatch(resetDataLayer());
    };
  }, [updateOn, shouldCallStr]);
};
