import React, { useCallback, useEffect, useRef, useState, Children } from 'react';

import { ArrowLeftThick, ArrowRightThick } from '@travel/icons/ui';
import { IconColor } from '@travel/icons/withIconProps';
import { cx, isNotEmptyArray } from '@travel/utils';

import {
  LeftIcon,
  RightIcon,
} from '../../../ui-components-ts/components/NavigationButton/navigationIcon';

import { EMPTY_ARRAY, EMPTY_FUNCTION } from '../../constants';
import { useIsInViewport, useOnResizeDebounce } from '../../hooks';

import styles from './swiper.module.scss';

export type Props = {
  className?: string;
  bottomWrapperClassName?: string;
  containerClassName?: string;
  /** String of custom pagination style type */
  paginationType?: 'swipe' | 'arrows';
  /** Nodes of inner children */
  children: React.ReactNode;
  /** Nodes of inner children */
  initialSlide?: number;
  /** Callback to be called when active child index has been changed */
  onChange?: (fromIndex: number, toIndex: number) => void;
  /** should disable swipe */
  shouldNotSwipe?: boolean;
  /** Trigger an update in cases of other than window resize */
  isResizeDependency?: boolean;
  /** Callback that will be called on click for pagination */
  paginationCallback?: (event: React.MouseEvent | React.TouchEvent | React.KeyboardEvent) => void;
  /** Hide arrow buttons only */
  shouldHideArrows?: boolean;
  /** Small Arrow UI for smaller swiper */
  isSmallArrows?: boolean;
  /** Should update wrapper width once it becomes visible in viewport */
  shouldUpdateOnceVisible?: boolean;
  /** Keep arrow buttons always visible */
  keepArrowsAlwaysVisible?: boolean;
  /** Use Sonic Silver as circle color instead of default white one */
  hasCircleColor?: boolean;
  /* is display the pagination circle buttons default true */
  isCirclePagination?: boolean;
  /** Make circle indicator clickable */
  hasClickableIndicator?: boolean;
  /** Show external navigation buttons */
  showNavButtons?: boolean;
  /* Class Name for the arrows */
  arrowClassName?: string;
  /* Class Name for the arrows. Default color: sonicSilver */
  arrowColor?: IconColor;
  navButtonWrapperClassName?: string;
  navButtonClassName?: string;
  /* Boolean to limit loading the children in the swiper */
  shouldRenderAllChild?: boolean;
  maxAdjacentChildren?: number;
  /** ARIA label for left button */
  ariaLabelLeftButton?: string;
  /** ARIA label for right button */
  ariaLabelRightButton?: string;
};

type ArrowProps = {
  currentIndex: number;
  onClickArrow: (
    direction: typeof DIRECTION_LEFT | typeof DIRECTION_RIGHT,
  ) => (event: React.MouseEvent | React.KeyboardEvent) => void;
  keepArrowsAlwaysVisible?: boolean;
  shouldHideArrows?: boolean;
  childrenCount?: number;
  arrowClassName?: string;
  arrowColor?: IconColor;
  ariaLabel?: string;
};

const MIN_SWIPE_DISTANCE = 25;
const DIRECTION_LEFT = 'left';
const DIRECTION_RIGHT = 'right';
const circleSize = 8;
const circleGap = 4;
const circlePlusGap = circleSize + circleGap;
const circleSmallSize = 4;
const circleSmallGap = 3;
const circleSmallPlusGap = circleSmallSize + circleSmallGap;

function LeftArrow(props: ArrowProps) {
  const {
    keepArrowsAlwaysVisible,
    shouldHideArrows,
    arrowClassName,
    onClickArrow,
    currentIndex = 0,
    arrowColor = 'sonicSilver',
    ariaLabel,
  } = props;
  const isVisible = keepArrowsAlwaysVisible || (!shouldHideArrows && currentIndex > 0);

  const onClick = useCallback(
    (event: React.MouseEvent) => {
      isVisible && onClickArrow('left')(event);
    },
    [isVisible, onClickArrow],
  );
  const onKeyDown = useCallback((event: React.KeyboardEvent) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.stopPropagation();
    }
  }, []);
  return (
    <button
      key={'left-button'}
      className={cx(arrowClassName, styles.prev, !isVisible && styles.hidden)}
      aria-label={ariaLabel}
      data-testid="swiper-previous-button"
      onClick={onClick}
      onKeyDown={onKeyDown}
      disabled={!isVisible}
    >
      <ArrowLeftThick className={styles.icon} size={12} color={arrowColor} />
    </button>
  );
}

function RightArrow(props: ArrowProps) {
  const {
    keepArrowsAlwaysVisible,
    shouldHideArrows,
    arrowClassName,
    onClickArrow,
    currentIndex = 0,
    childrenCount = 0,
    arrowColor = 'sonicSilver',
    ariaLabel,
  } = props;
  const isVisible =
    keepArrowsAlwaysVisible || (!shouldHideArrows && currentIndex < childrenCount - 1);
  const onClick = useCallback(
    (event: React.MouseEvent) => {
      isVisible && onClickArrow('right')(event);
    },
    [isVisible, onClickArrow],
  );
  const onKeyDown = useCallback((event: React.KeyboardEvent) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.stopPropagation();
    }
  }, []);
  return (
    <button
      key={'right-button'}
      className={cx(arrowClassName, styles.next, !isVisible && styles.hidden)}
      aria-label={ariaLabel}
      data-testid="swiper-next-button"
      onClick={onClick}
      onKeyDown={onKeyDown}
      disabled={!isVisible}
    >
      <ArrowRightThick className={styles.icon} size={12} color={arrowColor} />
    </button>
  );
}

function Swiper(props: Props) {
  const {
    onChange,
    isResizeDependency,
    isSmallArrows,
    shouldUpdateOnceVisible,
    shouldRenderAllChild = true,
    maxAdjacentChildren = 5,
    initialSlide,
  } = props;

  const wrapperRef = useRef<HTMLDivElement>(null);
  const isInViewport = useIsInViewport(wrapperRef);
  const itemWidthRef = useRef<number | null>(null);
  const childrenArr = Children.toArray(props.children);
  const childrenCount = isNotEmptyArray(childrenArr) ? childrenArr.length : 0;

  const swipeDirectionRef = useRef<typeof DIRECTION_LEFT | typeof DIRECTION_RIGHT | null>(null);
  const touchStartPositionRef = useRef<number | null>(null);
  const touchDiffPositionRef = useRef<number>(0);
  const [currentItemIndex, setCurrentItemIndex] = useState<number>(initialSlide ?? 0);
  const { isCirclePagination = true } = props;

  useEffect(() => {
    setCurrentItemIndex(initialSlide ?? 0);
  }, [initialSlide]);

  const [slideOffsets, setSlideOffsets] = useState<Array<number>>(EMPTY_ARRAY);
  const [positionX, setPositionX] = useState<number>(0);
  const [circleOffsets, setCircleOffsets] = useState<Array<number>>(EMPTY_ARRAY);
  const [posXCircle, setPosXCircle] = useState<number>(0);
  const [wrapperWidth, setWrapperWidth] = useState<number | null>(null);

  const update = useCallback(() => {
    if (wrapperRef.current) {
      const width = wrapperRef.current.getBoundingClientRect().width;
      const offsets = Array(childrenCount)
        .fill(0)
        .map((_, index) => width * index);

      setWrapperWidth(width);
      setSlideOffsets(offsets);
      setPositionX(offsets[currentItemIndex]);
      itemWidthRef.current = width;
      let offsetsCircle = Array.from({ length: childrenCount }, () => 0);
      if (childrenCount > 5) {
        offsetsCircle = Array.from({ length: childrenCount }, (_, i) => {
          const isLast3 = childrenCount - 4 < i;
          const cPlusGap = isSmallArrows ? circleSmallPlusGap : circlePlusGap;
          const cGap = isSmallArrows ? circleSmallGap : circleGap;
          if (isLast3) return (childrenCount - 5) * cPlusGap - cGap;
          return i < 2 ? 0 : (i - 2) * cPlusGap;
        });
      }
      setCircleOffsets(offsetsCircle);
      setPosXCircle(offsetsCircle[currentItemIndex]);
    }
  }, [childrenCount, isSmallArrows, currentItemIndex]);

  useEffect(() => {
    shouldUpdateOnceVisible && isInViewport && update();
  }, [shouldUpdateOnceVisible, isInViewport, update]);

  useEffect(() => {
    if (!itemWidthRef.current || isSmallArrows) {
      // added timeout since the width is 0 in some cases is even after this render loop.
      setTimeout(() => {
        update();
      }, 1);
    }
  }, [isSmallArrows, update]);

  useOnResizeDebounce(update);

  useEffect(() => {
    update();
  }, [isResizeDependency, update]);

  /** common functions */
  const gotoSlide = useCallback(
    (targetIndex: number) => {
      if (slideOffsets[targetIndex] !== undefined) {
        setPositionX(slideOffsets[targetIndex]);
        onChange?.(currentItemIndex, targetIndex);
        setCurrentItemIndex(targetIndex);
      } else {
        // if out of range, then reset the position
        setPositionX(slideOffsets[currentItemIndex]);
      }
      if (circleOffsets[targetIndex] !== undefined) {
        setPosXCircle(circleOffsets[targetIndex]);
      } else {
        setPosXCircle(circleOffsets[currentItemIndex]);
      }
    },
    [circleOffsets, onChange, slideOffsets, currentItemIndex],
  );

  const onStart = (offsetX: number) => {
    touchStartPositionRef.current = offsetX;
  };

  const onMove = useCallback(
    (offsetX: number) => {
      if (touchStartPositionRef.current === null) return false;

      const difference = offsetX - touchStartPositionRef.current;
      const direction = difference < 0 ? DIRECTION_LEFT : DIRECTION_RIGHT;

      if (direction === DIRECTION_LEFT) {
        setPositionX(slideOffsets[currentItemIndex] + Math.abs(difference));
      } else if (direction === DIRECTION_RIGHT) {
        setPositionX(slideOffsets[currentItemIndex] - Math.abs(difference));
      }

      swipeDirectionRef.current = direction;
      touchDiffPositionRef.current = Math.abs(difference);
    },
    [slideOffsets, currentItemIndex],
  );

  const onEnd = useCallback(() => {
    if (itemWidthRef.current === null) return false;

    let nextItemIndex = currentItemIndex;

    if (
      swipeDirectionRef.current === DIRECTION_LEFT &&
      touchDiffPositionRef.current > MIN_SWIPE_DISTANCE
    ) {
      nextItemIndex = currentItemIndex + 1;
    } else if (
      swipeDirectionRef.current === DIRECTION_RIGHT &&
      touchDiffPositionRef.current > MIN_SWIPE_DISTANCE
    ) {
      nextItemIndex = currentItemIndex - 1;
    }

    gotoSlide(nextItemIndex);
    touchStartPositionRef.current = null;
    touchDiffPositionRef.current = 0;
  }, [gotoSlide, currentItemIndex]);

  const onCancel = useCallback(() => {
    setPositionX(slideOffsets[currentItemIndex]);
    setPosXCircle(circleOffsets[currentItemIndex]);
    touchStartPositionRef.current = null;
  }, [circleOffsets, slideOffsets, currentItemIndex]);

  /** mouse event handler */
  const onMouseDown = (event: React.MouseEvent) => onStart(event.clientX);

  const onMouseMove = useCallback(
    (event: React.MouseEvent) => {
      // to prevent selecting ghost image
      event.preventDefault();
      onMove(event.clientX);
    },
    [onMove],
  );

  /** touch event handler */
  const onTouchStart = (event: React.TouchEvent) => {
    event.stopPropagation();
    onStart(event.targetTouches?.[0].clientX);
  };

  const onTouchMove = useCallback(
    (event: React.TouchEvent) => {
      event.stopPropagation();
      onMove(event.targetTouches?.[0].clientX);
    },
    [onMove],
  );

  const onClickArrow = (direction: 'right' | 'left') => (
    event: React.MouseEvent | React.KeyboardEvent,
  ) => {
    event.preventDefault();
    const leftIndex = currentItemIndex === 0 ? childrenCount - 1 : currentItemIndex - 1;
    const rightIndex = currentItemIndex === childrenCount - 1 ? 0 : currentItemIndex + 1;
    const targetIndex = direction === 'left' ? leftIndex : rightIndex;

    gotoSlide(targetIndex);
    props.paginationCallback?.(event);
  };

  function onClickCircleIndicator(index: number) {
    if (index === currentItemIndex) return;
    gotoSlide(index);
  }

  const { arrowClass, circleWrapClassName, circleClassName, bottomWrapClass } = isSmallArrows
    ? {
        arrowClass: styles.arrowSmall,
        circleWrapClassName: styles.circleSmallWrap,
        circleClassName: styles.circlesSmall,
        bottomWrapClass: styles.bottomWrapperSmall,
      }
    : {
        arrowClass: styles.arrow,
        circleWrapClassName: styles.circleWrap,
        circleClassName: styles.circles,
        bottomWrapClass: styles.bottomWrapper,
      };

  const arrowClassName = cx(props.arrowClassName, arrowClass);

  return (
    <>
      <div
        data-testid="swiper-wrapper"
        className={cx(styles.wrapper, props.className)}
        ref={wrapperRef}
      >
        <div
          className={cx(styles.container, props.containerClassName)}
          style={{
            transform: `translateX(-${positionX}px)`,
          }}
          {...(!props.shouldNotSwipe
            ? {
                onTouchStart,
                onMouseDown,
                onTouchMove,
                onMouseMove,
                onTouchEnd: onEnd,
                onMouseUp: onEnd,
                onTouchCancel: onCancel,
                onMouseLeave: onCancel,
              }
            : null)}
          onFocus={EMPTY_FUNCTION}
          role="presentation"
        >
          {isNotEmptyArray(childrenArr) &&
            childrenArr.map((child, index: number) => {
              // We will download the first 5 images others will be lazy loaded
              if (
                shouldRenderAllChild ||
                Math.abs(index - currentItemIndex) <= maxAdjacentChildren
              ) {
                return (
                  <div
                    key={index}
                    className={styles.sliderItem}
                    style={{ width: wrapperWidth ? `${wrapperWidth}px` : undefined }}
                  >
                    {child}
                  </div>
                );
              } else {
                return (
                  <div
                    key={index}
                    className={styles.sliderItem}
                    style={{ width: wrapperWidth ? `${wrapperWidth}px` : undefined }}
                  />
                );
              }
            })}
        </div>

        {/* pagination */}
        {childrenCount > 1 && (
          <>
            {isCirclePagination && (
              <div
                className={cx(bottomWrapClass, props.bottomWrapperClassName, circleWrapClassName)}
              >
                <div
                  className={circleClassName}
                  style={{
                    transform: `translateX(-${posXCircle}px)`,
                  }}
                >
                  {childrenArr.map((_el, i) => {
                    const activeIndex = currentItemIndex;
                    const diffFromCurr = Math.abs(i - activeIndex);
                    const isLastOrFirst =
                      activeIndex === 0 || activeIndex === childrenArr.length - 1;
                    const isSmallerCircle =
                      childrenCount > 5 && diffFromCurr === (isLastOrFirst ? 3 : 2);
                    const isTinyCircle =
                      childrenCount > 5 &&
                      diffFromCurr > (isLastOrFirst ? 3 : 2) &&
                      !isSmallerCircle;
                    return (
                      <div
                        data-testid="swiper-circleBox-div"
                        className={isSmallArrows ? styles.circleSmallBox : styles.circleBox}
                        key={i}
                        aria-hidden="true"
                        onClick={() => onClickCircleIndicator(i)}
                      >
                        <div
                          className={
                            i === activeIndex
                              ? cx(
                                  styles.smallCircle,
                                  styles.activeCircle,
                                  props.hasCircleColor && styles.circleColor,
                                )
                              : cx(
                                  styles.smallCircle,
                                  props.hasCircleColor && styles.circleColor,
                                  props.hasClickableIndicator && styles.cursorStyle,
                                  isSmallerCircle && styles.smallerCircle,
                                  isTinyCircle && styles.tinyCircle,
                                )
                          }
                          data-testid={`swiper-${
                            !isSmallArrows && (isTinyCircle || isSmallerCircle) ? 'smallerC' : 'c'
                          }ircle-div`}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {props.paginationType === 'arrows' && (
              <div className={styles.centerWrap}>
                <LeftArrow
                  keepArrowsAlwaysVisible={props.keepArrowsAlwaysVisible}
                  shouldHideArrows={props.shouldHideArrows}
                  currentIndex={currentItemIndex}
                  arrowClassName={arrowClassName}
                  onClickArrow={onClickArrow}
                  arrowColor={props.arrowColor}
                  ariaLabel={props.ariaLabelLeftButton}
                />
                <RightArrow
                  keepArrowsAlwaysVisible={props.keepArrowsAlwaysVisible}
                  shouldHideArrows={props.shouldHideArrows}
                  currentIndex={currentItemIndex}
                  childrenCount={childrenCount}
                  arrowClassName={arrowClassName}
                  onClickArrow={onClickArrow}
                  arrowColor={props.arrowColor}
                  ariaLabel={props.ariaLabelRightButton}
                />
              </div>
            )}
          </>
        )}
      </div>

      {props.showNavButtons && (
        <div className={cx(styles.navButtonWrapper, props.navButtonWrapperClassName)}>
          <button
            className={cx(styles.navButton, styles.left, props.navButtonClassName)}
            data-testid={`navigationButton-left`}
            onClick={onClickArrow('left')}
            aria-label={props.ariaLabelLeftButton}
          >
            <span key="icon" className={styles.navIcon}>
              <LeftIcon />
            </span>
          </button>

          <button
            className={cx(styles.navButton, styles.right, props.navButtonClassName)}
            data-testid={`navigationButton-right`}
            onClick={onClickArrow('right')}
            aria-label={props.ariaLabelRightButton}
          >
            <span key="icon" className={styles.navIcon}>
              <RightIcon />
            </span>
          </button>
        </div>
      )}
    </>
  );
}

export default Swiper;
