import React from 'react';

import { seoKeys } from '../../utils/seo';

export type SalesPromotionFormQuery = {
  /** String of exact sales promotion id */
  salesPromotionId?: string;
  /** String of promotion code */
  promotionCode?: string;
};

export type InternalSalesPromotionFormQuery = {
  /** String if navigated from Coupon Search form */
  isCouponSpecified?: string;
};

function SalesPromotionInput(props: SalesPromotionFormQuery & InternalSalesPromotionFormQuery) {
  return (
    <>
      {props.salesPromotionId && (
        <input
          id="salesPromotionID-input"
          data-testid="salesPromotionID-input"
          type="hidden"
          value={props.salesPromotionId}
          name={seoKeys.salesPromotionId}
        />
      )}
      {props.promotionCode && (
        <input
          id="promotionCode-input"
          data-testid="promotionCode-input"
          type="hidden"
          value={props.promotionCode}
          name={seoKeys.promotionCode}
        />
      )}
      {props.isCouponSpecified && (
        <input
          id="promotionCode-input"
          type="hidden"
          value={props.isCouponSpecified}
          name={'isCouponSpecified'} //Since its a non-seo parameter, its better to have this name
        />
      )}
    </>
  );
}

export default SalesPromotionInput;
