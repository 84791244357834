import { ApiClient } from '@travel/api-client';

import { ProviderListItems } from 'ProviderList-Types';

import { SearchRequestBody } from '../../pages/ProviderListPage/resolver';

export const fetchSuggestedProviderListItems = (
  http: ApiClient,
  req: SearchRequestBody,
): Promise<ProviderListItems> =>
  http
    .post('/monterosa-api/v2/provider-summaries', {
      method: 'POST',
      req: { ...req },
      values: { ...req },
    })
    .then((data: ProviderListItems) => {
      return data;
    });
