import React, { useEffect, useRef, useState, MouseEvent, ReactNode } from 'react';

import useDeviceType from '../../utils/useDeviceType';

import isNotEmptyArray from '../../utils/arrays';
import cx from '../../utils/classnames';
import {
  getPrevTranslateX,
  setCondition,
  CarouselNavButtonContainer,
  DISPLAY_CARDS,
} from './helper';

import styles from './contentsCarousel.module.scss';

const INITIAL_CARD_WIDTH = 166;

export type Props = {
  /** Style name to control appearance of this component from parent component*/
  className?: string;
  /** Style name to control apperances of section containing contents */
  contentsContainerClassName?: string;
  children?: ReactNode;
  /** Custom width of each card inside the component */
  fixedCardWidth?: number;
  /** Custom style for nav button */
  navButtonClassName?: string;
  /** Defined number of displayed cards at any given time in carousel */
  displayCount?: number;
  /** ARIA label for left button */
  ariaLabelLeftButton?: string;
  /** ARIA label for right button */
  ariaLabelRightButton?: string;
};

export default function ContentsCarousel(props: Props) {
  const defaultSwipeState = { x: 0, swiping: false, startLeft: 0 };
  const [displayItemCount, setDisplayItemCount] = useState<number>(
    props.displayCount || DISPLAY_CARDS.PC,
  );
  const [current, setCurrent] = useState<number>(0);
  const [wrapperHeight, setWrapperHeight] = useState<number | null>(null);
  const [contentHeight, setContentHeight] = useState<number | null>(null);
  const [cardWidth, setCardWidth] = useState<number>(props.fixedCardWidth || INITIAL_CARD_WIDTH);
  const [swipe, setSwipe] = useState(defaultSwipeState);

  const deviceType = useDeviceType();
  const isPC = deviceType === 'pc';

  const contentsRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const updateTranslateX = (x: number) => {
    if (contentsRef && contentsRef.current)
      contentsRef.current.style.transform = `translateX(${x}px)`;
  };

  useEffect(() => {
    const setConditionWrapper = () => {
      setCondition(
        deviceType,
        current,
        cardWidth,
        wrapperRef,
        contentsRef,
        setWrapperHeight,
        setContentHeight,
        setCardWidth,
        setDisplayItemCount,
        updateTranslateX,
        props.fixedCardWidth,
        props.displayCount,
      );
    };
    window.addEventListener('resize', setConditionWrapper);
    setConditionWrapper();

    return () => {
      window.removeEventListener('resize', setConditionWrapper);
    };
  }, [cardWidth, current, deviceType, props.displayCount, props.fixedCardWidth]);

  const toggleClick = (e: string) => {
    if (deviceType !== 'sp') {
      if (e === 'left' && current > 0) {
        setCurrent(current - 1);
      } else if (
        e === 'right' &&
        isNotEmptyArray(props.children) &&
        current < props.children.length - displayItemCount
      ) {
        setCurrent(current + 1);
      }
    }
  };

  const handleMouseDown = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    setSwipe({ swiping: true, x: e.clientX, startLeft: getPrevTranslateX(contentsRef) });
  };

  const handleMouseMove = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (swipe.swiping) {
      const delta = e && e.clientX - swipe.x;
      if (getPrevTranslateX(contentsRef) !== swipe.startLeft + delta) {
        updateTranslateX(swipe.startLeft + delta);
      }
    }
  };

  const handleMouseUpLeave = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (swipe.swiping) {
      if (deviceType === 'sp') {
        const delta = e.clientX - swipe.x;
        if (contentsRef.current) {
          if (
            contentsRef.current.scrollWidth <= contentsRef.current.clientWidth ||
            swipe.startLeft + delta > 0
          ) {
            updateTranslateX(0);
          } else if (
            swipe.startLeft + delta <
            contentsRef.current.clientWidth - contentsRef.current.scrollWidth
          ) {
            updateTranslateX(contentsRef.current.clientWidth - contentsRef.current.scrollWidth);
          }
        }
      } else {
        const delta = e.clientX - swipe.x;
        if (
          delta < -cardWidth / 2 &&
          isNotEmptyArray(props.children) &&
          current < props.children.length - displayItemCount
        ) {
          setCurrent(current + 1);
        } else if (delta > cardWidth / 2 && current > 0) {
          setCurrent(current - 1);
        }
      }
      setSwipe(defaultSwipeState);
    }
  };

  return (
    <div className={styles.arrowWrapper}>
      <div
        className={cx(styles.carousel, props.contentsContainerClassName)}
        style={(wrapperHeight && { height: wrapperHeight }) || {}}
      >
        <CarouselNavButtonContainer
          current={current}
          onClick={toggleClick}
          length={(isNotEmptyArray(props.children) && props.children.length) || 0}
          displayItemCount={displayItemCount}
          buttonClassName={cx(styles.carouselButton, props.navButtonClassName)}
          ariaLabelLeftButton={props.ariaLabelLeftButton}
          ariaLabelRightButton={props.ariaLabelRightButton}
        >
          <div
            data-testid="contentsCarousel-content"
            className={cx(styles.carousel, props.contentsContainerClassName)}
            style={(contentHeight && { height: contentHeight }) || {}}
          >
            <div className={cx(styles.carouselWrapper, props.className)} ref={wrapperRef}>
              <div
                className={styles.contents}
                ref={contentsRef}
                onMouseDown={!isPC ? handleMouseDown : undefined}
                onMouseMove={!isPC ? handleMouseMove : undefined}
                onMouseUp={!isPC ? handleMouseUpLeave : undefined}
                onMouseLeave={!isPC ? handleMouseUpLeave : undefined}
                role="presentation"
              >
                {props.children}
              </div>
            </div>
          </div>
        </CarouselNavButtonContainer>
      </div>
    </div>
  );
}

ContentsCarousel.defaultProps = {
  className: '',
  children: [],
};
