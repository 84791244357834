import { AppThunk, createAsyncAction, createStandardAction } from 'typesafe-actions';

import { SearchRequestBody } from 'pages/ProviderListPage/resolver';

import { Location } from 'ProviderInformation-Types';
import { ProviderListErrors, ProviderListItems } from 'ProviderList-Types';

import { fetchSuggestedProviderListItems } from './api';

type FetchProviderListOptionType = {
  isMapSearch?: boolean;
  isSuggestionSearch?: boolean;
  page?: number;
};

type FetchProviderListPayload = {
  response: ProviderListItems;
  options?: FetchProviderListOptionType;
};

export const clearProviderList = createStandardAction('CLEAR_SUGGESTED_PROVIDER_LIST')<Location>();

export const fetchSuggestedProviderAsync = createAsyncAction(
  'FETCH_SUGGESTED_PROVIDER_LIST_REQUEST',
  'FETCH_SUGGESTED_PROVIDER_LIST_SUCCESS',
  'FETCH_SUGGESTED_PROVIDER_LIST_FAILURE',
)<undefined, FetchProviderListPayload, ProviderListErrors[]>();

export const fetchSuggestedProviderList = (
  req: SearchRequestBody,
  options?: FetchProviderListOptionType,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(fetchSuggestedProviderAsync.request());

  try {
    const response = await fetchSuggestedProviderListItems(apiClient, req);

    dispatch(fetchSuggestedProviderAsync.success({ response, options }));
  } catch (error) {
    dispatch(fetchSuggestedProviderAsync.failure(error as ProviderListErrors[]));
  }
};
