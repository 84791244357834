import { combineReducers } from 'redux';

import { reducer as httpRequestReducer } from '@travel/http-request';
import { reducer as i18nReducer } from '@travel/i18n';
// ranStore
import ranStoreReducer from '@travel/traveler-core/store/ranStore/reducer';

import routerReducer from './__router/reducer';
// CouponList
import acquiredCouponsReducer from './acquiredCoupons/reducer';
// AreaPage
import areaDetailsReducer from './areaDetails/reducer';
import authCodeReducer from './authCode/reducer';
import bannerReducer from './banner/reducer';
import bookingStepReducer from './bookingStep/reducer';
// BookmarkList
import bookmarksReducer from './bookmarks/reducer';
import cancelStepReducer from './cancelStep/reducer';
import cardReducer from './card/reducer';
import commonErrorReducer from './commonError/reducer';
import countriesReducer from './countries/reducer';
import postEsimCampaignReducer from './eSimCampaign/reducer';
// Store to hold all the feature flags and configurations
import featureConfigReducer from './featureConfig/reducer';
import findARoomBtnReducer from './findARoomBtn/reducer';
import generalContentsReducer from './generalContents/reducer';
// GuestReviewListPage
import guestReviewsReducer from './guestReviews/reducer';
import heroImagesReducer from './heroImages/reducer';
import introductionBannerReducer from './introductionBanner/reducer';
import labelReducer from './labels/reducer';
import legacyCancelStepReducer from './legacyCancelStep/reducer';
import legacyReservationDetailsReducer from './legacyReservationDetails/reducer';
// Legacy Reservation
import legacyReservationListReducer from './legacyReservationList/reducer';
import marketsReducer from './markets/reducer';
// Media
import mediaReducer from './media/reducer';
import memberReducer from './member/reducer';
import modificationStepReducer from './modificationStep/reducer';
import notificationDetailsReducer from './notificationDetails/reducer';
// Notifications
import notificationsReducer from './notifications/reducer';
import omniReducer from './omni/reducer';
import pageLoadingReducer from './pageLoading/reducer';
// Review
import postReviewReducer from './postReview/reducer';
import promotionBannersReducer from './promotionBanners/reducer';
//Provider Information
import providerInformationReducer from './providerInformation/reducer';
import suggestedProvidersReducer from './suggestedProviderList/reducer';
//ProviderListPage
import providerListReducer from './providerList/reducer';
import providerPlanListReducer from './providerPlanList/reducer';
import providerRatePlanReducer from './providerRatePlan/reducer';
import providerReviewReducer from './providerReview/reducer';
import providerViewersReducer from './providerViewers/reducer';
import reservationDetailsReducer from './reservationDetails/reducer';
import reservationEntranceReducer from './reservationEntrance/reducer';
// Reservation
import reservationListReducer from './reservationList/reducer';
// SalesPromotion
import salesPromotionReducer from './salesPromotion/reducer';
import seoReducer from './seo/reducer';
import specialFeatureArticlesReducer from './specialFeatureArticles/reducer';
import subscriptionReducer from './subscription/reducer';
//Top Page related reducers
import suggestionsReducer from './suggestions/reducer';
import userAgentReducer from './userAgent/reducer';
// Vacancy Calendar
import vacancyCalendarReducer from './vacancyCalendar/reducer';

export default combineReducers({
  //BMC
  omni: omniReducer,
  bookingStep: bookingStepReducer,
  modificationStep: modificationStepReducer,

  //core
  authCode: authCodeReducer,
  router: routerReducer,
  userAgent: userAgentReducer,
  commonError: commonErrorReducer,
  _i18n: i18nReducer,
  _httpRequest: httpRequestReducer,
  markets: marketsReducer,
  seo: seoReducer,
  member: memberReducer,
  countries: countriesReducer,
  pageLoading: pageLoadingReducer,

  //Top Page
  suggestions: suggestionsReducer,
  banner: bannerReducer,
  introductionBanner: introductionBannerReducer,
  generalContents: generalContentsReducer,
  promotionBanners: promotionBannersReducer,
  specialFeatureArticles: specialFeatureArticlesReducer,
  heroImages: heroImagesReducer,

  //ProviderList
  providerList: providerListReducer,

  //Provider Information
  providerInformation: providerInformationReducer,
  providerRatePlan: providerRatePlanReducer,
  providerPlanList: providerPlanListReducer,
  providerReview: providerReviewReducer,
  providerViewers: providerViewersReducer,
  findARoomBtn: findARoomBtnReducer,
  suggestedProviders: suggestedProvidersReducer,

  // Account Management
  card: cardReducer,
  subscription: subscriptionReducer,

  // Review
  postReview: postReviewReducer,

  //GuestReviewListPage
  guestReviews: guestReviewsReducer,

  // Reservation
  reservationList: reservationListReducer,
  reservationDetails: reservationDetailsReducer,
  reservationEntrance: reservationEntranceReducer,

  // Legacy Reservation
  legacyReservationList: legacyReservationListReducer,
  legacyReservationDetails: legacyReservationDetailsReducer,
  legacyCancelStep: legacyCancelStepReducer,

  // Media
  media: mediaReducer,

  // Cancel steps
  cancelStep: cancelStepReducer,

  // CouponList
  acquiredCoupons: acquiredCouponsReducer,

  // BookmarkList
  bookmarks: bookmarksReducer,

  // SalesPromotion
  salesPromotion: salesPromotionReducer,

  // AreaPage
  areaDetails: areaDetailsReducer,

  // Translation Labels
  labels: labelReducer,
  notifications: notificationsReducer,
  notificationDetails: notificationDetailsReducer,

  // RAN store
  ranStore: ranStoreReducer,

  // Esim campaign
  postEsimCampaign: postEsimCampaignReducer,

  //Feature Configurations
  featureConfig: featureConfigReducer,

  // Vacancy Calendar
  vacancyCalendar: vacancyCalendarReducer,
});
