import { EMPTY_ARRAY } from './defaultValue';

import { OptionItem } from './types';

export type SortKeysType =
  | 'recommended'
  | 'price_low_to_high'
  | 'price_high_to_low'
  | 'distance_spot'
  | 'star_rating_low_to_high'
  | 'star_rating_high_to_low'
  | 'guest_review_score';

type SortOption = {
  [key: string]: OptionItem & {
    queries: {
      sort: 'recommend_v2' | 'price' | 'distance' | 'rating' | 'review_score';
      order: 'asc' | 'desc';
    };
  };
};

/**********************************
 * FILTER
 *********************************/
export const FILTER_GROUP_IDS = {
  SORT: 'sortBy',
  PRICE: 'priceFilter',
  RATING: 'ratingFilter',
  REVIEW_SCORE: 'reviewScoreFilter',
  CANCELLATION_POLICY: 'cancellationPolicy',
};

export enum SORT_OPTIONS_DEFAULT_TRANSLATION {
  recommended = 1,
  price_low_to_high,
  price_high_to_low,
  distance_spot,
  star_rating_low_to_high,
  star_rating_high_to_low,
  guest_review_score,
}

/**********************************
 * SORT
 *********************************/
export const SORT_OPTIONS: SortOption = {
  recommended: {
    labelId: 'Traveler_Enumeration.Sort.Provider_Recommended.Most',
    value: 'recommended',
    queries: {
      sort: 'recommend_v2',
      order: 'asc',
    },
  },
  price_low_to_high: {
    labelId: 'Traveler_Enumeration.Sort.Provider_Price.Low_High',
    value: 'price_low_to_high',
    queries: {
      sort: 'price',
      order: 'asc',
    },
  },
  price_high_to_low: {
    labelId: 'Traveler_Enumeration.Sort.Provider_Price.High_Low',
    value: 'price_high_to_low',
    queries: {
      sort: 'price',
      order: 'desc',
    },
  },
  distance_spot: {
    labelId: 'Traveler_Enumeration.Sort.Distance_From_Spot.Close_Far',
    value: 'distance_spot',
    queries: {
      sort: 'distance',
      order: 'asc',
    },
  },
  star_rating_low_to_high: {
    labelId: 'Traveler_Enumeration.Sort.Provider_Rating.Low_High',
    value: 'star_rating_low_to_high',
    queries: {
      sort: 'rating',
      order: 'asc',
    },
  },
  star_rating_high_to_low: {
    labelId: 'Traveler_Enumeration.Sort.Provider_Rating.High_Low',
    value: 'star_rating_high_to_low',
    queries: {
      sort: 'rating',
      order: 'desc',
    },
  },
  guest_review_score: {
    labelId: 'Traveler_Enumeration.Sort.Provider_Review_Score.High_Low',
    value: 'guest_review_score',
    queries: {
      sort: 'review_score',
      order: 'desc',
    },
  },
};

export const PROVIDER_INFO_SORT_OPTIONS: SortOption = {
  price_low_to_high: {
    labelId: 'Traveler_Enumeration.Sort.Provider_Price.Low_High',
    value: 'price_low_to_high',
    queries: {
      sort: 'price',
      order: 'asc',
    },
  },
  price_high_to_low: {
    labelId: 'Traveler_Enumeration.Sort.Provider_Price.High_Low',
    value: 'price_high_to_low',
    queries: {
      sort: 'price',
      order: 'desc',
    },
  },
};

/**********************************
 * PAYMENT MANAGEMENT NAME
 *********************************/
export enum PLAN_PAYMENT_MANAGEMENT_NAME {
  CREDIT_CARD_PAYMENT_ONLY = 'credit_card_prepayment_only',
  CASH_ONLY = 'cash_only',
}

export enum SP_PAYMENT_MANAGEMENT_NAME {
  AGENT_PAYMENT_ONLY = 'agent_payment_only',
  ONSITE_PAYMENT_ONLY = 'onsite_payment_only',
}

/**********************************
 * PLACE NAVIGATION
 *********************************/
export enum PLACE_NAVIGATION {
  AREA = 'Area_Top',
  PROVIDER_LIST = 'Provider_List',
}

export const DEFAULT_ADULT = 2;
export const DEFAULT_ROOMS = 1;
export const DEFAULT_CHILDREN_AGES = EMPTY_ARRAY;
