import {
  AreaRelatedItems,
  AreaRelatedItemsCategory,
} from '@travel/traveler-core/types/areaDetails';

export const defaultAreaRelated: AreaRelatedItems = {
  placeName: '',
  parentName: '',
  category: AreaRelatedItemsCategory.SUBDIVISION,
  relatedPlacesValid: false,
  pointsOfInterest: [],
  trainStations: [],
  hotSprings: [],
};

// A city level area is defined as for example: cnt_japan/sub_tokyo/cty_mitaka_city
// Anything under this (here: cnt_japan/sub_tokyo), won't have any point of interests to return
export function isCityLevelAreaOrSmaller(pathId?: string) {
  return !!pathId && pathId.split('/').length > 2;
}
