import { createSelector } from 'reselect';
import { RootState } from 'typesafe-actions';

import { getMarket, MarketType } from '@travel/i18n';

import { getCountries } from 'store/countries/selectors';
import { getItem as getMemberData } from 'store/member/selectors';
import { getItems } from 'store/reservationDetails/selectors';

import {
  BookingForm,
  BookingStep3,
  BookingStepData,
  BookingStepErrors,
  SalesPromotion,
} from 'BookingStep-Types';
import { NAME_INPUT_REGEX } from 'constants/bookingStep1Form';
import { CountriesItems } from 'Countries-Types';
import { MemberItem } from 'Member-Types';
import { ReservationDetailsItems } from 'ReservationDetails-Types';

import { BookingStepState } from './reducer';

const DEFAULT_LAST_CHECK_IN_TIME = '24:00';

export const getBooking = (state: RootState) => state.bookingStep;
export const getErrors = (state: RootState) => state.bookingStep.errors;

export const getBookingStepData = (state: RootState) => state.bookingStep.bookingData;

export const getBookingStep3 = (state: RootState) => state.bookingStep.step3;

export const getCouponRequest = (state: RootState) => state.bookingStep.couponRequestBody;

export const getChildNum = (state: RootState) => state.bookingStep.bookingData.childAges;

export const getBinRestrictedCouponId = (state: RootState) =>
  state.bookingStep.bookingData.appliedBinRestrictedCouponId;

export const getBookingForm = createSelector(
  getBooking,
  getMemberData,
  getMarket,
  getCountries,
  (
    booking: BookingStepState,
    member: MemberItem,
    market: MarketType,
    countries: CountriesItems,
  ) => {
    const { bookingForm, bookingData } = booking;

    const { noOfAdults, childAges } = bookingData;
    const noOfGuest = noOfAdults + (childAges ? childAges.length : 0);

    // searches the alpha2Code of the country based on the member's mobilePhoneCountryCallingCode
    const memberCountryCodeWithoutPlus = member.mobilePhoneCountryCallingCode?.replace('+', '');
    const memberCountryCode = Object.keys(countries).find(
      key => countries[key].callingCode === memberCountryCodeWithoutPlus,
    );

    // order of priority:
    // 1. existing booking step data saved in state
    // 2. logged-in user has data in myRakuten account
    // 3. fetch data from current market
    // 4. default (empty string)
    const countryCode =
      bookingForm.applicant?.countryCode ||
      (memberCountryCode && countries[memberCountryCode]?.alpha2Code) ||
      countries[market.marketCode]?.alpha2Code ||
      '';
    const phoneCountryCode =
      bookingForm.applicant?.phoneCountryCode ||
      member.mobilePhoneCountryCallingCode ||
      countries[market.marketCode]?.callingCode ||
      '';

    if (Object.keys(member).length === 0) {
      if (bookingForm.applicant.firstName) {
        bookingForm.itemPlans[0].guests[0].firstName = bookingForm.applicant.firstName;
      }
      if (bookingForm.applicant.lastName) {
        bookingForm.itemPlans[0].guests[0].lastName = bookingForm.applicant.lastName;
      }

      bookingForm.itemPlans = bookingForm.itemPlans.map(item => {
        const guests = Array(noOfGuest)
          .fill(undefined)
          .map((_, index) => {
            return item.guests[index]
              ? item.guests[index]
              : {
                  firstName: '',
                  lastName: '',
                  representative: false,
                  guestSequence: index + 1,
                  age: index >= noOfAdults && childAges ? childAges[index - noOfAdults] : null,
                };
          });
        // null for adult (representative is always adult)
        guests[0].age = null;
        return {
          ...item,
          guests,
        };
      });

      return {
        ...bookingForm,
        applicant: {
          ...bookingForm.applicant,
          email: bookingData.nonMemberApplicantEmail || bookingForm.applicant.email,
          countryCode: countryCode,
          phoneCountryCode:
            `${phoneCountryCode}`.substring(0, 1) === '+'
              ? phoneCountryCode
              : `+${phoneCountryCode}`,
        },
      };
    }

    const applicantEmail = bookingForm.nonMemberApplicantEmail
      ? bookingForm.nonMemberApplicantEmail
      : member.email;
    const firstName =
      bookingForm?.applicant?.firstName ||
      (NAME_INPUT_REGEX.test(member.firstName) && member.firstName) ||
      '';
    const lastName =
      bookingForm?.applicant?.lastName ||
      (NAME_INPUT_REGEX.test(member.lastName) && member.lastName) ||
      '';
    const phoneNumber = bookingForm.applicant?.phoneNumber || member.mobilePhoneNumber || '';

    bookingForm.itemPlans = bookingForm.itemPlans.map((item, itemIndex) => {
      const guests = Array(noOfGuest)
        .fill(undefined)
        .map((_, index) => {
          return item.guests[index] &&
            item.guests[index].firstName !== '' &&
            item.guests[index].lastName !== ''
            ? item.guests[index]
            : {
                // auto-filling the name of the representative of the first room
                firstName: index === 0 && itemIndex === 0 && firstName ? firstName : '',
                lastName: index === 0 && itemIndex === 0 && lastName ? lastName : '',
                representative: index === 0,
                guestSequence: index + 1,
                age: index >= noOfAdults && childAges ? childAges[index - noOfAdults] : null,
              };
        });
      guests[0].age = null;
      return {
        ...item,
        guests,
      };
    });

    return {
      ...bookingForm,
      applicant: {
        firstName: firstName,
        lastName: lastName,
        email: applicantEmail,
        countryCode: countryCode,
        membership: 'RAKUTEN',
        phoneCountryCode:
          `${phoneCountryCode}`.substring(0, 1) === '+' ? phoneCountryCode : `+${phoneCountryCode}`,
        phoneNumber: phoneNumber,
      },
    };
  },
);

export const getArrivalTime = createSelector(
  getBookingStepData,
  (state: BookingStepData) => state.expectedArrivalTime,
);

export const getProviderNotices = createSelector(
  getBookingStepData,
  (state: BookingStepData) => state.providerNotices,
);

export const getProviderQuestions = createSelector(
  getBookingStepData,
  (state: BookingStepData) => state.providerQuestions,
);

export const getProviderCheckInTime = createSelector(
  getBookingStepData,
  (state: BookingStepData) => ({
    startCheckInTime: state.providerCheckInTime,
    endCheckInTime: state.providerLastCheckInTime
      ? state.providerLastCheckInTime
      : DEFAULT_LAST_CHECK_IN_TIME,
  }),
);

export const getPricingDetails = createSelector(
  getBookingStepData,
  (state: BookingStepData) => state.pricingDetails,
);

export const getAllowPointUsage = createSelector(
  getBookingStepData,
  (state: BookingStepData) => state.allowPointUsage,
);

export const getUserCurrency = createSelector(
  getBookingStepData,
  (state: BookingStepData) => state.userCurrency,
);

export const getSavedCards = createSelector(
  getBookingStepData,
  (state: BookingStepData) => state.savedCards,
);

export const getAvailablePaymentMethods = createSelector(
  getBookingStepData,
  (state: BookingStepData) => state.availablePaymentMethods,
);

export const getTotalEarnedPoints = createSelector(
  getBookingStepData,
  (state: BookingStepData) => state.totalEarnedPoints,
);

export const getTaxInformation = createSelector(getBookingStepData, (state: BookingStepData) => {
  const { includeTax, excludeTax } = state;

  return {
    inclusive: includeTax,
    exclusive: excludeTax,
  };
});

export const getProviderCurrency = createSelector(
  getBookingStepData,
  (state: BookingStepData) => state.providerCurrency,
);

export const getBookingStep1Total = createSelector(
  getBookingStepData,
  (state: BookingStepData) => ({
    providerCurrencyTotal: state.totalPriceInProviderCurrency,
    userCurrencyTotal: state.totalPriceInUserCurrency,
    afterDiscountTotal: state.totalPriceUserAfterDiscount,
    userCurrency: state.userCurrency,
    providerCurrency: state.providerCurrency,
    earnPoint: state.totalEarnedPoints,
  }),
);

export const getFeatureGroups = createSelector(getBookingStepData, (state: BookingStepData) => {
  const { item, ratePlan, provider } = state;
  return {
    itemFeatureGroups: item.featureGroups,
    ratePlanFeatureGroups: ratePlan.featureGroups,
    providerFeatureGroups: provider.featureGroups,
  };
});

export const getRoomInformation = createSelector(getBookingStepData, (state: BookingStepData) => {
  const {
    itemName,
    itemNameGoogleTranslated,
    bedSizes,
    capacity,
    roomSize,
    itemVisualContent,
    ratePlanName,
    ratePlanDescription,
    itemView,
    isRatePlanTextsGoogleTranslated,
  } = state;

  return {
    itemName,
    isItemNameGoogleTranslated: itemNameGoogleTranslated,
    bedSizes,
    capacity,
    roomSize,
    itemVisualContent,
    ratePlanName,
    ratePlanDescription,
    itemView: itemView?.text,
    isRatePlanTextsGoogleTranslated,
  };
});

export const getReservationDetails = createSelector(
  getBookingStepData,
  (state: BookingStepData) => {
    const {
      checkInDate,
      checkOutDate,
      providerName,
      roomCount,
      providerCheckInTime,
      providerCheckOutTime,
      providerLastCheckInTime,
      noOfAdults,
      childAges,
      totalPriceInProviderCurrency,
    } = state;

    return {
      checkInDate,
      checkOutDate,
      providerName,
      roomCount,
      providerCheckInTime,
      providerCheckOutTime,
      providerLastCheckInTime,
      noOfAdults,
      childrenAges: childAges,
      noOfChildren: childAges?.length,
      totalPriceInProviderCurrency,
    };
  },
);

export const getCancelPolicyDetail = createSelector(
  getBookingStepData,
  (step1: BookingStepData) => step1.cancelPolicyDetails,
);

export const getPointUnitOfUsage = createSelector(
  getBookingStepData,
  (step1: BookingStepData) => step1.pointUnitOfUsage,
);

export const getBookingFormApplicant = createSelector(
  getBookingForm,
  (state: BookingForm) => state.applicant,
);

export const getBookingStepSalesPromotion = createSelector(
  getBookingStepData,
  (state: BookingStepData) => {
    // in mult booking, remove which promotion have useMax===1 and already has been selected by one room.
    let useMaxItemId = '';
    state?.pricingDetails?.map(pricingDetail =>
      pricingDetail.salesPromotions.map(item => {
        if (item.detail.maxUse === 1 && item.selected) {
          useMaxItemId = item.detail.id;
        }
        return item;
      }),
    );

    if (useMaxItemId) {
      state?.pricingDetails?.map(
        pricingDetail =>
          (pricingDetail.salesPromotions = pricingDetail.salesPromotions.filter(item => {
            return item.detail.id !== useMaxItemId || item.selected;
          })),
      );
    }

    const salePromotion = state?.pricingDetails?.reduce((salesPromotionData, pricingDetail) => {
      salesPromotionData[pricingDetail.echoId] = pricingDetail.salesPromotions;
      return salesPromotionData;
    }, {} as { [key: string]: SalesPromotion[] });

    return salePromotion || {};
  },
);

export const getBookingStep2Id = createSelector(
  getBookingStepData,
  (state: BookingStepData) => state.stepId,
);

export const getApplicantEmail = createSelector(
  getBookingStep3,
  getItems,
  (bookingStep3Data: BookingStep3, reservationDetails: ReservationDetailsItems) =>
    bookingStep3Data.applicantEmail || reservationDetails.email,
);

export const getMemberInformation = createSelector(
  getBookingStepData,
  (bookingData: BookingStepData) => bookingData.memberInformation,
);

export const getIsFetching = createSelector(
  getBooking,
  (state: BookingStepState) => state.isFetching,
);

export const getIsFetchingStep1 = createSelector(
  getBooking,
  (state: BookingStepState) => state.isFetchingStep1,
);

export const getIsFetchingStep3 = createSelector(
  getBooking,
  (state: BookingStepState) => state.isFetchingStep3,
);

export const getItemId = createSelector(
  getBookingStepData,
  (state: BookingStepData) => state.pricingDetails[0].itemId,
);

export const getRatePlanId = createSelector(
  getBookingStepData,
  (state: BookingStepData) => state.pricingDetails[0].itemRatePlanId,
);

export const getNonMemberApplicantEmail = createSelector(
  getBookingStepData,
  (state: BookingStepData) => state.nonMemberApplicantEmail,
);

export const getPaymentData = createSelector(getBookingStepData, (state: BookingStepData) => ({
  selectedPaymentMethod: state.selectedPaymentMethod,
}));

export const getPaymentAmountInJpy = createSelector(
  getBookingStep3,
  (state: BookingStep3) => state.paymentAmountInJpy,
);

export const getAppliedCoupon = createSelector(
  getBooking,
  (state: BookingStepState) => state.appliedCoupon,
);

export const getCheckoutStage = createSelector(
  getBooking,
  (state: BookingStepState) => state.checkoutStage,
);

export const getError = createSelector(getErrors, (state: BookingStepErrors) => {
  if (state?.errors) {
    return state.errors.length > 0 ? state.errors?.[0] : null;
  } else {
    return null;
  }
});

export const getErrorStatusCode = createSelector(getErrors, (state: BookingStepErrors) => {
  return state?.status;
});

export const getSelectedSalePromotions = createSelector(
  getBookingStepData,
  (state: BookingStepData) => {
    return (
      state?.pricingDetails?.reduce((curr: Array<string | undefined>, pricingDetail) => {
        const selectedPromotions = pricingDetail.salesPromotions.filter(
          salesPromotion => salesPromotion.selected,
        );

        const mainIds = selectedPromotions.map(salesPromotion => salesPromotion.detail.id);
        const subIds = pricingDetail.salesPromotions
          .map(salesPromotion => salesPromotion?.subPromotions)
          .flat()
          .filter(subSalesPromotion => subSalesPromotion?.selected)
          .map(subSalesPromotion => subSalesPromotion?.detail.id);

        return [...curr, ...mainIds, ...subIds].filter(id => id);
      }, []) || []
    );
  },
);

export const getProviderFacilities = createSelector(
  getBookingStepData,
  (state: BookingStepData) => {
    const { featureGroups } = state;

    return featureGroups
      ?.filter(featureGroup => featureGroup.type === 'Facility')[0]
      ?.features.map(feature => feature.name)
      .filter(facility => facility);
  },
);
