import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { ProviderFeature } from '@travel/traveler-core/types/providerList';
import { GalleryMedia } from '@travel/ui/components/Gallery';

import { ProviderItem, ProviderList } from 'ProviderList-Types';

import { fetchSuggestedProviderAsync } from './actions';

export const isFetchingSuggestedProviders = createReducer(false as boolean)
  .handleAction([fetchSuggestedProviderAsync.request], () => true)
  .handleAction(
    [fetchSuggestedProviderAsync.success, fetchSuggestedProviderAsync.failure],
    () => false,
  );

export const suggestedProviders = createReducer({} as ProviderList)
  .handleAction(fetchSuggestedProviderAsync.success, (state, { payload }) => {
    const { providers, ...restResponse } = payload.response;
    const modifiedProviders = providers.map((provider: ProviderItem) => {
      const {
        features,
        media,
        ...rest
      }: {
        features: Array<ProviderFeature>;
        media: Array<GalleryMedia>;
      } = provider;

      return { media: media.slice(0, 1), ...rest };
    });
    const page = payload.options?.page || 1;

    return {
      ...restResponse,
      providers: modifiedProviders,
      page,
    };
  })

  .handleAction(
    fetchSuggestedProviderAsync.failure,
    () => ({ total: 0, limit: 0, offset: 0, page: 1 } as ProviderList),
  );

const suggestedProvidersReducer = combineReducers({
  suggestedProviders,
  isFetchingSuggestedProviders,
});

export default suggestedProvidersReducer;
export type SuggestedProviderListState = ReturnType<typeof suggestedProvidersReducer>;
