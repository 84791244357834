import { useSelector } from 'react-redux';

import { getL10nNumber } from './L10nNumber';

import { IMPERIAL_SYSTEM } from '../constants';
import { getMarket } from '../selectors';
import { getMeasurementSystem } from '../utils/l10n';

const DEFAULT_DECIMALS = 0; // round to 0 decimals places
const CONVERSION_SQ_METER_TO_SQ_FEET = 10.7639;

/**
 * calculate the number of decimals to display based on the value. If all digits after the decimal point are zeros, then return 0 decimals else return the given decimals
 * example:
 * 100 => 0, 100.0 => 0, 100.00 => 0,
 * 100.1 => 1, 100.10 => 1, 100.100 => 1
 * @param value given value
 * @param decimals given decimals
 * @returns number of decimals to display
 */
const getDecimals = (value: number, decimals: number): number => {
  const numStr = value.toString();
  // Check if the number has a decimal point
  if (numStr.includes('.')) {
    // check if all digits after the decimal point are zeros
    return /\.0+$/.test(numStr) ? 0 : decimals;
  }
  // there is no decimal point
  return 0;
};

/**
 * Returns localized version of area provided unit of measurement
 * @param value  value in String or Number
 * @param unit  unit of measurement
 * @param decimals number of decimals to display (default: 0)
 * @param market current user market
 * @returns localized area
 */
function getL10nArea(
  value: string | number,
  unit: string,
  decimals = DEFAULT_DECIMALS,
  market?: string,
) {
  value = Number(value);
  const marketFromStore = useSelector(getMarket)?.marketCode || '';
  const marketLocale = market || marketFromStore;
  const measurementSystem = getMeasurementSystem(marketLocale);
  const isImperialSystem = measurementSystem === IMPERIAL_SYSTEM;

  let unitLocale = unit;
  let valueLocale = value;
  if (unit === 'm²' && isImperialSystem) {
    unitLocale = 'ft²';
    valueLocale = value * CONVERSION_SQ_METER_TO_SQ_FEET;
  }
  const decimalsLocale = getDecimals(valueLocale, decimals);

  return `${getL10nNumber(marketLocale, valueLocale, decimalsLocale)} ${unitLocale}`;
}

export { getL10nArea };
