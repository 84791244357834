import React from 'react';

import cx from '../../utils/classnames';

import styles from './skeleton.module.scss';

type Props = {
  /** Custom style for wrapper */
  className?: string;
  /** Height size */
  height?: string;
  /** Width size */
  width?: string;
  /** It define shape for skeleton */
  type?: 'image' | 'paragraph';
  /** If type is paragraph, it define number of row */
  row?: number;
  /** It define pragraph length */
  length?: 'short' | 'long';
};

function Skeleton(props: Props) {
  const {
    className,
    height = '300px',
    width = '300px',
    type = 'image',
    row = 3,
    length = 'short',
    ...rest
  } = props;

  return (
    <>
      {type === 'image' ? (
        <div
          data-testid="component-skeleton"
          className={cx(className, styles.skeleton)}
          {...rest}
          style={{
            minHeight: height,
            minWidth: width,
          }}
        />
      ) : (
        <div
          data-testid="component-skeleton"
          className={cx(className, styles.paragraph, styles[length])}
          {...rest}
        >
          {Array(row)
            .fill(0)
            .map((_item, index) => (
              <div
                data-testid="skeleton-item"
                key={index}
                className={styles.skeleton}
                style={{ width: `${100 - (100 / row) * index}%` }}
              />
            ))}
        </div>
      )}
    </>
  );
}

export default Skeleton;
