import dayjs from 'dayjs';
import React, { MouseEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getCurrency, L10nDate } from '@travel/i18n';
import { ArrowRight, Checkmark } from '@travel/icons/ui';
import { useDialogHandler } from '@travel/traveler-core/hooks';
import useDeviceType, {
  DESKTOP_SCREEN,
  MOBILE_SCREEN,
} from '@travel/traveler-core/hooks/useDeviceType';
import { FlatButton, Tag } from '@travel/ui';
import isNotEmptyArray from '@travel/ui/utils/arrays';
import { isEmptyObject } from '@travel/utils';
import { cx } from '@travel/utils/src/classNames';

import CouponSkeleton from 'components/CouponSkeleton';
import Dialog from 'components/Dialog';
import ErrorMessage from 'components/ErrorMessage';
import IncentiveValueTitle from 'components/IncentiveValueTitle';
import Money from 'components/Money';
import PriceFor from 'components/PriceFor';
import { CouponItem } from 'pages/ProviderInformationPage/components/PlanCouponItem/components/CouponItem';

import { fetchCouponDetails } from 'store/salesPromotion/actions';
import { getCouponDetails, getIsCouponDetailFetching } from 'store/salesPromotion/selectors';
import { getDetails, getErrors } from 'store/vacancyCalendar/selectors';

import { Translate } from 'core/translate';

import styles from './priceAndCoupons.module.scss';

export type InfoBoxProps = {
  rooms: number;
  adults: number;
  childrenAges: number[];
  startDate?: string;
  endDate?: string;
  onClick: () => void;
  onChangeCouponDialog: (state: boolean) => void;
};

function PriceAndCoupons({
  rooms,
  adults,
  childrenAges,
  startDate,
  endDate,
  onClick,
  onChangeCouponDialog,
}: InfoBoxProps) {
  const dispatch = useDispatch();
  const isSP = useDeviceType() === MOBILE_SCREEN;
  const isPC = useDeviceType() === DESKTOP_SCREEN;
  const [selectedCouponInfo, setSelectedCouponInfo] = useState({
    salesPromotionId: '',
    isApplied: false,
  });

  const details = useSelector(getDetails);
  const currency = useSelector(getCurrency);
  const errors = useSelector(getErrors);
  const isCouponDetailFetching = useSelector(getIsCouponDetailFetching);
  const couponDetail = useSelector(getCouponDetails(selectedCouponInfo.salesPromotionId));

  // if any coupon is not same with user currency, show annotation message
  const isSameWithCouponCurrency = couponDetail?.localCurrency === currency;
  const nightCount = startDate && endDate ? dayjs(endDate).diff(dayjs(startDate), 'day') : 1;

  const { isOpen, onClose, onOpen } = useDialogHandler(false);

  const onCloseCoupon = () => {
    onClose();
    onChangeCouponDialog(false);
  };

  const onOpenCoupon = (salesPromotionId: string, isApplied: boolean) => {
    onOpen();
    onChangeCouponDialog(true);
    dispatch(fetchCouponDetails(salesPromotionId));
    setSelectedCouponInfo({ salesPromotionId, isApplied });
  };

  /**
   * This mouse down event is necessary to prevent close button triggers
   * blur event of another dialog.
   */
  const handleCloseButtonMouseDown = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const closeButtonProps = {
    onMouseDown: handleCloseButtonMouseDown,
  };

  if (isNotEmptyArray(errors) && errors[0]) {
    return (
      <div
        className={styles.bottomWrapper}
        data-testid="vacancyCalendar-priceAndCoupons-error-wrapper"
      >
        <ErrorMessage
          message={<Translate id="Provider_Info.Vacancy_Calendar.Error_Message" />}
          className={styles.errorMessageWrapper}
          iconClassName={styles.errorIcon}
        />
        <FlatButton
          onClick={onClick}
          classType="primary"
          type="button"
          className={styles.button}
          isDisabled={true}
        >
          <Translate
            id="Provider_Info.Plan_List.Room_Info.Vacancy_Calendar.Update"
            count={nightCount}
            data={{
              number_of_nights: nightCount,
            }}
          />
        </FlatButton>
      </div>
    );
  }

  if (!(startDate && endDate) || !details.price) {
    return (
      <div
        className={cx(styles.bottomWrapper, styles.unSelectedDate)}
        data-testid="vacancyCalendar-priceAndCoupons-unselectedDate-wrapper"
      >
        {isSP && (
          <div className={styles.currencyInfo}>
            *
            <Translate
              id="Provider_Info.Vacancy_Calendar.Currency_Explanation"
              data={{
                currency_symbol: currency,
              }}
            />
          </div>
        )}
        <FlatButton onClick={onClick} classType="primary" type="button" className={styles.button}>
          <Translate id="Provider_Info.Room_List.Plan_Info.Vacancy_Calendar.Select_Dates_later" />
        </FlatButton>
      </div>
    );
  }

  return (
    <>
      <div className={styles.bottomWrapper} data-testid="vacancyCalendar-priceAndCoupons-wrapper">
        <div className={styles.couponWrapper}>
          {isNotEmptyArray(details?.acquiredCoupons) &&
            details.acquiredCoupons.map(item => (
              <button
                key={item.couponId}
                className={cx(styles.coupon, item.applied && styles.isApplied)}
                data-testid="vacancyCalendar-coupon"
                onClick={() => onOpenCoupon(item.couponId, item.applied)}
              >
                <div className={styles.couponTitle}>
                  <IncentiveValueTitle
                    {...item.incentiveAmount}
                    currency={currency}
                    className={styles.couponIncentive}
                  />
                  {item.title}
                </div>

                <Translate
                  id="Provider_Info.Vacancy_Calendar.Coupon_Widget.Period"
                  data={{
                    startdate: (
                      <L10nDate value={item.promotionStart} format={'L'} isDisplayAsLocalTime />
                    ),
                    enddate: (
                      <L10nDate value={item.promotionEnd} format={'L'} isDisplayAsLocalTime />
                    ),
                  }}
                />

                {item.applied && (
                  <span className={styles.applied}>
                    <Checkmark size={12} color="darkCyan" />
                    <Translate id="Provider_Info.Vacancy_Calendar.Coupon_Widget.Applied" />
                  </span>
                )}
                <ArrowRight size={16} color="blueRaspberry" className={styles.icon} />
              </button>
            ))}
        </div>
        <div className={styles.container}>
          <div
            className={styles.priceWrapper}
            data-testid="vacancyCalendar-priceAndCoupons-priceWrapper"
          >
            <div className={styles.content}>
              {details.price.base && details.price.base !== details.price.total && (
                <div className={styles.discountWrapper}>
                  <Money className={styles.basePrice} value={details.price.base} />
                  {!!details.price.discountRate && details.price.discountRate >= 2 && (
                    <Tag
                      type="filled"
                      color={'scarlet'}
                      className={styles.tag}
                      data-testid="priceLabel-discountRate-tag"
                    >
                      -{details.price.discountRate}%
                    </Tag>
                  )}
                </div>
              )}

              <Money className={styles.totalPrice} value={details.price.total} />
            </div>

            <PriceFor
              rooms={rooms}
              adults={adults}
              childrenAges={childrenAges}
              startDate={startDate}
              endDate={endDate}
            />
          </div>
          <FlatButton
            onClick={onClick}
            classType="primary"
            type="button"
            className={styles.button}
            data-testid="vacancyCalendar-priceAndCoupons-updateButton"
          >
            <Translate
              id="Provider_Info.Plan_List.Room_Info.Vacancy_Calendar.Update"
              count={nightCount}
              data={{
                number_of_nights: nightCount,
              }}
            />
          </FlatButton>
        </div>
      </div>

      {!isEmptyObject(couponDetail) ? (
        <Dialog
          isOpen={isOpen}
          isSEOContentIndexedOnHidden={true}
          onClose={onCloseCoupon}
          title={isPC ? null : <Translate id="Provider_Info.Provider_Info.Coupon.Title" />}
          className={styles.couponItemDialog}
          contentClassName={styles.couponDetail}
          isDisableAutoFocus={true}
          closeButtonProps={closeButtonProps}
          hasAnimation={true}
        >
          <div
            className={styles.contentWrapper}
            data-testid="providerInfo-ratePlanList-planCouponItem-dialogWrapper"
          >
            {isPC && (
              <h2 className={styles.couponDetailTitle}>
                <Translate id="Provider_Info.Provider_Info.Coupon.Title" />
              </h2>
            )}
            <Translate
              className={styles.annotation}
              id="Coupon_Details.Price_Disclaimer"
              condition={() =>
                `status == ${isSameWithCouponCurrency ? 'same_currency' : 'different_currency'}`
              }
            />
            <div className={styles.couponItems}>
              {isCouponDetailFetching ? (
                <CouponSkeleton isCouponAccordionOpen={true} />
              ) : (
                <CouponItem
                  salesPromotionId={''}
                  couponDetail={couponDetail}
                  isAcquired={true}
                  isDetailInitiallyOpen={true}
                  handleCouponLinkSuccess={() => {}}
                  data-testid="vacancyCalendar-couponItem"
                  shouldShowCouponButton={selectedCouponInfo.isApplied}
                  shouldShowAccordionButton={false}
                />
              )}
            </div>
          </div>
        </Dialog>
      ) : null}
    </>
  );
}

export default PriceAndCoupons;
