import { getCurrencySign } from '@travel/i18n';
import { getL10nDate } from '@travel/i18n/src';
import { getL10nArea } from '@travel/i18n/src/components/L10nArea';
import { Checkmark } from '@travel/icons/ui';
import { GetTranslation } from '@travel/translation';
import { useTranslation } from '@travel/traveler-core/hooks';
import { Facility } from '@travel/traveler-core/types/providerRatePlan';
import { isEmptyArray, isNotEmptyArray } from '@travel/utils';

import { EMPTY_ARRAY } from 'constants/defaultValue';
import { PLAN_FEATURES } from 'constants/planFeatures';
import { ProviderInformation } from 'ProviderInformation-Types';
import { BedSizeItem, Capacity, Feature, RoomSizeItem } from 'ProviderRatePlan-Types';
import { ProviderReviewList } from 'ProviderReview-Types';
import { roundReviewScore } from 'utils/review';

type IconText = {
  id: string;
  icon?: string;
  name?: string;
  text?: string;
  // detail
  title?: string;
  description?: string;
};

interface IconTextListData extends Omit<IconText, 'icon'> {
  groupId: undefined;
  icon: React.ReactNode;
}

export function getCapacityText(capacity: Capacity, getTranslation: GetTranslation) {
  const { minGuests, maxGuests } = capacity;
  if (!minGuests || !maxGuests) {
    return '';
  }

  return getTranslation({
    id: 'Common_Plan_Details.Room_Plan_Info.Room_Info.Capacity',
    data: {
      min_guests: minGuests,
      max_guests: maxGuests,
    },
  });
}

export function getMaxAdultChild(capacity: Capacity, getTranslation: GetTranslation) {
  const { maxAdults, maxChildren } = capacity;
  if (!maxAdults && !maxChildren) {
    return '';
  }

  const maxText = getTranslation({ id: 'Common_Plan_Details.Room_Plan_Info.Room_Info.Max' });
  const adultText =
    !!maxAdults &&
    getTranslation({
      id: 'Common_Plan_Details.Room_Plan_Info.Room_Info.Adult',
      count: maxAdults,
      data: { adult_guest_count: maxAdults },
    });
  const childText =
    !!maxChildren &&
    getTranslation({
      id: 'Common_Plan_Details.Room_Plan_Info.Room_Info.Child',
      count: maxChildren,
      data: { child_count: maxChildren },
    });

  return `${maxText} ${
    adultText && childText ? `${adultText} / ${childText}` : `${adultText ? adultText : childText}`
  } `;
}

export function getBedText(beds: Array<BedSizeItem>) {
  if (isEmptyArray(beds)) {
    return '';
  }
  return beds?.map((bed: BedSizeItem) => `${bed.amount || 1} x ${bed.name}`).join(', ');
}

export function getRoomSizeText(room?: RoomSizeItem) {
  return room?.area && room?.unit ? getL10nArea(room.area, room.unit, 1) : null;
}

export function mappedIconTextListData(
  facilities: Array<IconText>,
  iconClassName?: string,
  iconPlaceHolder: React.ReactNode = <Checkmark size={16} />,
) {
  if (isEmptyArray(facilities)) {
    return EMPTY_ARRAY;
  }

  // to remove duplicated feature id
  const features: IconText[] = Object.values(
    (facilities || EMPTY_ARRAY).reduce(
      (result, facility) => ({ ...result, [facility.id]: facility }),
      {},
    ),
  );

  return isNotEmptyArray(features)
    ? features.reduce((result, { name, text, icon, ...rest }) => {
        const label = name || text; // TODO: Remove `item.text` when all API are using `name` as feature title
        if (!label || label.trim().length === 0) {
          return result;
        }

        return [
          ...result,
          {
            text: label,
            icon: icon ? (
              <img src={icon} alt={name || text} className={iconClassName} loading={'lazy'} />
            ) : (
              iconPlaceHolder || null
            ),
            ...rest,
            groupId: undefined,
          },
        ];
      }, [] as IconTextListData[])
    : EMPTY_ARRAY;
}

export function getFilteredRoomPlanFeatures(
  facilities?: Array<Facility>,
  start?: number,
  end?: number,
) {
  return mappedIconTextListData(
    isNotEmptyArray(facilities)
      ? facilities
          .reduce(
            (featureList, currentFacility) => [...featureList, ...currentFacility.features],
            [] as Array<Feature>,
          )
          .filter(
            feature =>
              feature.id !== PLAN_FEATURES.NO_MEAL && feature.id !== PLAN_FEATURES.CREDIT_CARD_ONLY, // To avoid duplication with paymentRestrictionFeature & hasMeal
          )
          .slice(start, end)
      : EMPTY_ARRAY,
  );
}

/**
 * For use with analytics.
 * @param hasMeal If plan includes meal.
 * @param freeUntil Free cancellation deadline for plan if available.
 * @param facilities Plan facilities.
 * @param start Start index to filter feature facilities.
 * @param end End index to filter facilities.
 * @returns Array of feature text.
 */
export function getExtendedFilteredRoomPlanFeatures(
  hasMeal: boolean,
  freeUntil?: string,
  facilities?: Array<Facility>,
  start?: number,
  end?: number,
) {
  let features = [];
  if (freeUntil) {
    features.push(
      useTranslation({
        id: 'Cancellation_Policy_Common.Provider_Pages.Free_Cancellation',
        data: { free_cancellation_date: getL10nDate('', freeUntil, 'MD', true, '') },
      }),
    );
  }
  if (hasMeal) {
    features.push(useTranslation({ id: 'Provider_Info.Luxury.Meal' }));
  } else {
    features.push(useTranslation({ id: 'Common_Price_Details.Guests.No_Meal' }));
  }
  let filteredFeatures = getFilteredRoomPlanFeatures(facilities, start, end).flatMap(feature =>
    feature.text ? [feature.text] : [],
  );
  return features.concat(filteredFeatures).slice(start, end);
}

export function generateProviderInfoJsonLd(
  provider: ProviderInformation,
  reviewScoreInfo: ProviderReviewList,
  currency: string,
  priceRange?: number,
) {
  const {
    address: { postCode, addressLine } = {},
    catchCopy,
    contact: { phoneInfo = [] } = {},
    media = [],
  } = provider;
  const priceRangeText = useTranslation({
    id: 'Provider_Info.SEO.Structured_Data.Price_Range',
    data: {
      lowest_price_with_currency_symbol: `${getCurrencySign(currency)}${priceRange}`,
      currency_code: currency,
    },
  });
  return {
    '@context': 'https://schema.org',
    '@type': 'Hotel',
    name: provider?.name || '',
    address: {
      '@type': 'PostalAddress',
      postalCode: postCode,
      streetAddress: addressLine,
    },
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue: reviewScoreInfo.averageScore
        ? roundReviewScore(reviewScoreInfo.averageScore)
        : '',
      ratingCount: reviewScoreInfo.total || '',
    },
    description: catchCopy || '',
    telephone: phoneInfo?.[0] || '',
    image: media[0]?.url || '',
    priceRange: priceRange ? priceRangeText : '',
  };
}

// Match the ids of the features in query to their values
export function createScondIds(mapping: { [p: string]: string }, features?: string): string {
  return Object.entries(mapping)
    .reduce((acc, [key, val]) => {
      if (features?.includes(val)) {
        return [...acc, key];
      }
      return acc;
    }, [] as Array<string>)
    .join(',');
}
