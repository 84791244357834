import { useEffect } from 'react';

import { DeviceType } from '../../hooks/useDeviceType';
import { Groups, ProviderFilters } from '../../types/providerList';

import { SearchConditionsAndOr } from '../../types/analytics';

/**
 * Converts 'tl' layout to 'tb' for RAT devices. Returns the input layout unchanged for other values.
 *
 * @param {DeviceType} layout - The layout type to be converted.
 * @returns {DeviceType} - The converted layout type, 'tb' if input is 'tl', otherwise returns the original layout.
 */
export function convertRatPageLayout(layout: DeviceType) {
  if (layout === 'tl') {
    return 'tb';
  }
  return layout;
}

/** This is defined in (V1.4) PRD_InternalTools004_User behavior tracking
 * CAUTION: Don't change the code */
export const dispatchRatEvent = () => {
  if (typeof window !== 'undefined') {
    let event;
    if (typeof window.Event === 'function') {
      event = new Event('SINGLE_PAGE_APPLICATION_LOAD');
      document.dispatchEvent(event);
    } else {
      try {
        event = document.createEvent('Event');
        event.initEvent('SINGLE_PAGE_APPLICATION_LOAD', true, true);
        document.dispatchEvent(event);
      } catch (e) {
        console.error(e);
      }
    }
  }
};

/**
 * Generic and custom parameter PRD is updating in progress
 * Generic: https://confluence.rakuten-it.com/confluence/display/EVEREST/%28V1.1%29+Everest+website+RAT+generic+parameters
 * Custom: https://confluence.rakuten-it.com/confluence/display/EVEREST/%28V1.1%29+Everest+website+RAT+custom+parameters
 * Site Section: https://confluence.rakuten-it.com/confluence/display/EVEREST/Everest+RAT+page+names+and+site+sections
 */
export const useRat = (updateData?: string, isAsync = true) => {
  useEffect(() => {
    if (isAsync) {
      setTimeout(() => {
        dispatchRatEvent();
      }, 100);
    } else {
      dispatchRatEvent();
    }
    // I only want to dispatch when one of these props changes else we will get performance issues
  }, [isAsync, updateData]);
};

/**
 * Determines if filters are selected in multiple categories (a), only in the same category (o), or none at all (x).
 *
 * @param {ProviderFilters} filterGroups - An array of filter group objects containing various filter details.
 * @param {string} [maxPrice] - The maximum price filter value to compare.
 * @param {string} [minPrice] - The minimum price filter value to compare.
 * @returns {SearchConditionsAndOr} - 'a' if filters are selected in multiple categories, 'o' if only in the same category, 'x' if none at all.
 */
export const checkSearchConditions = (
  filterGroups: ProviderFilters,
  maxPrice?: string,
  minPrice?: string,
): SearchConditionsAndOr => {
  const selectedGroups = filterGroups.flatMap(group => {
    const selectedFreeCancellation = group.freeCancellation?.selected ? [group.groupId] : [];

    const selectedFeatures =
      group.features?.filter((feature: Groups) => feature.selected).map(() => group.groupId) || [];

    const selectedRatings =
      group.rating?.filter(rating => rating.selected).map(() => group.groupId) || [];

    const minPriceCheck =
      minPrice !== undefined && Math.floor(group.min || 0) !== parseInt(minPrice);
    const maxPriceCheck =
      maxPrice !== undefined && Math.floor(group?.max || 0) !== parseInt(maxPrice);
    const priceFilterChanged =
      group.groupId === 'priceFilter' && (minPriceCheck || maxPriceCheck) ? [group.groupId] : [];

    return [
      ...selectedFreeCancellation,
      ...selectedFeatures,
      ...selectedRatings,
      ...priceFilterChanged,
    ];
  });

  const uniqueSelectedGroups = [...new Set(selectedGroups)];

  return selectedGroups.length === 0 ? 'x' : uniqueSelectedGroups.length > 1 ? 'a' : 'o';
};
