import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getLanguage, getMarket, L10nDate } from '@travel/i18n';
import { Flight, Hotel, Station } from '@travel/icons/service';
import { Search, Spot, TimeOutline, Trash } from '@travel/icons/ui';
import {
  convertRatPageLayout,
  dispatchRatEvent,
} from '@travel/traveler-core/components/DataLayer/utils';
import HtmlDialog from '@travel/traveler-core/components/HtmlDialog';
import {
  DESKTOP_SCREEN,
  MOBILE_SCREEN,
  useDeviceType,
  useDialogHandler,
  useTranslation,
} from '@travel/traveler-core/hooks';
import { SuggestionType } from '@travel/traveler-core/types/suggestions';
import { AutoCompleteMulti, FlatButton, TextField } from '@travel/ui';
import { cx, isEmptyArray, isNotEmptyArray } from '@travel/utils';

import DataLayer from 'components/DataLayer';
import ErrorMessage from 'components/ErrorMessage';
import WithSkeletonLoading from 'components/WithSkeletonLoading';

import { updateAreaName } from 'store/providerList/actions';
import { fetchSuggestions } from 'store/suggestions/actions';
import { getIsFetching, getItems } from 'store/suggestions/selectors';
import { getIsIOS } from 'store/userAgent/selectors';

import { RAT_PAGE_TYPE_OBJ } from 'constants/ratAnalytics';
import { Translate } from 'core/translate';
import useOutsideClick from 'hooks/useOutsideClick';
import { SuggestionsItem, SuggestionsSection } from 'Suggestions-Types';
import { convertToSuggestionSection, getPopularDestinations } from 'utils/popularDestinations';
import { deleteSavedSearch, savedSearchToPlaceSuggestion } from 'utils/savedSearch';

import EmptyState from './EmptyState';

import styles from './placeInput.module.scss';

type Props = {
  className?: string;
  /** String of component style each page */
  onPage:
    | 'top'
    | 'providerListDated'
    | 'providerListUndated'
    | 'providerInfoDated'
    | 'providerInfoUndated'
    | 'couponSearch'
    | 'area';
  /** String of default path id */
  placeId?: string;
  /** Callback to be called on place changed */
  onChangePlace: (place: SuggestionsItem) => void;
  /** Callback to be called on place selected */
  onSelectPlace?: (isSavedSearch?: boolean, hasDate?: boolean, isFromBlur?: boolean) => void;
  /** Callback to be called on place deleted */
  onDeletePlace?: () => void;
  /** String of default title */
  defaultText: string;
  /** String of text field placeholder */
  destinationTextLabel?: string;
  /** To Disable the text field */
  isDisabled?: boolean;
  /** String to define the place scope */
  pathIdScope?: string;
  /** Flag to define if field has error */
  hasError?: boolean;
  /** Input field error message */
  errorMessage?: ReactNode;
  /** Style name to control the appearance of error message */
  errorMessageClassName?: string;
  /** Flag to enable animation when component mounted */
  hasAnimation?: boolean;
};

// TODO: All category icons are not defined in Figma yet.
const ICON_MAP = {
  PROVIDER: <Hotel />,
  TRAIN_STATION: <Station />,
  AIRPORT: <Flight />,
  WORLD_REGION: <Spot />,
  REGION: <Spot />,
  COUNTRY: <Spot />,
  SUBDIVISION: <Spot />,
  CITY: <Spot />,
  DISTRICT: <Spot />,
  POINT_OF_INTEREST: <Spot />,
  BUS_STOP: <Spot />,
  SEAPORT: <Spot />,
  ISLAND: <Spot />,
  HOT_SPRING: <Spot />,
  POPULAR_DESTINATION: <Spot />,
  NONE: <Spot />,
  SAVED_SEARCH: <TimeOutline size={21} />,
  UNDEFINED: null,
};

const DEBOUNCE_TIME = 400;
const SUGGESTION_ITEM_LIMIT_PC = 5;
const SUGGESTION_ITEM_LIMIT_NON_PC = 7;
const CHARACTER_LIMIT = 100;

export const EMPTY_PLACES = {
  title: null,
  type: null,
  suggestions: [
    {
      id: '',
      pathId: '',
      type: 'AREA',
      category: 'UNDEFINED',
      name: '',
    } as SuggestionsItem,
  ],
} as SuggestionsSection;

const EMPTY_SUGGESTION = {
  title: null,
  type: null,
  suggestions: [],
};

function PlaceInput(props: Props) {
  const dispatch = useDispatch();
  const deviceType = useDeviceType();
  const isPC = deviceType === DESKTOP_SCREEN;
  const isMobile = deviceType === MOBILE_SCREEN;
  const {
    isOpen: isMobileDialogOpen,
    onClose: onMobileDialogClose,
    onOpen: onMobileDialogOpen,
  } = useDialogHandler(false);
  const [text, setText] = useState(props.defaultText);
  const [isPending, setIsPending] = useState(false);
  const [isActivateSearch, setIsActivateSearch] = useState(false);
  const [isShowSavedSearch, setIsShowSavedSearch] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [savedSearch, setSavedSearch] = useState<SuggestionsSection>(EMPTY_SUGGESTION);
  const [popularDestinations, setPopularDestinations] = useState<SuggestionsSection>();
  const suggestions = convertToSuggestionSection(useSelector(getItems));
  const isLoading = useSelector(getIsFetching);
  const language = useSelector(getLanguage);
  const market = useSelector(getMarket);
  const marketCode = market.marketCode;
  const isIOS = useSelector(getIsIOS);

  const isCouponSearch = props.onPage === 'couponSearch';

  const { pageType, siteSection, parentName } = RAT_PAGE_TYPE_OBJ[props.onPage];

  // the javascript input element. It's used for focusing
  const inputEl = useRef<HTMLInputElement | null>(null);

  const destinationLabel = useTranslation({ id: 'Top.Modals.Destination.Placeholder' });
  const destinationText = props.destinationTextLabel || destinationLabel;

  const isInitialState = useRef<boolean>(true);
  const isSelectSuggestedItem = useRef(false);
  const pendingTimerRef = useRef<NodeJS.Timeout | number>(0);
  const blurTimeoutFunc = useRef<NodeJS.Timeout | number>(0);
  const lastSelectedText = useRef(props.defaultText);
  const clickAwayRef = useRef<HTMLDivElement>(null);

  const isOnTopPage = props.onPage === 'top';
  const isOnAreaPage = props.onPage === 'area';
  const isOnProviderList =
    props.onPage === 'providerListUndated' || props.onPage === 'providerListDated';
  const isOnProviderInfo =
    props.onPage === 'providerInfoDated' || props.onPage === 'providerInfoUndated';
  const isSaveSearchPage = isOnProviderList || isOnProviderInfo || isOnTopPage;

  useEffect(() => {
    const savedSearches = isSaveSearchPage && savedSearchToPlaceSuggestion(language);
    if (savedSearches) {
      setSavedSearch({
        title: (
          <div className={styles.recentSearchLabel}>
            <Translate id="Traveler_Common.Keyword_Search.Recent_Search" />
          </div>
        ),
        type: SuggestionType.SAVED_SEARCHES,
        suggestions: savedSearches,
      });
    }

    const popularDestinations = getPopularDestinations(marketCode, language);

    if (isNotEmptyArray(popularDestinations)) {
      setPopularDestinations({
        title: (
          <div className={styles.recentSearchLabel}>
            <Translate id="Traveler_Common.Keyword_Search.Popular_Destinations" />
          </div>
        ),
        type: SuggestionType.POPULAR_DESTINATION,
        suggestions: popularDestinations,
      });
    }
  }, [isSaveSearchPage, language, marketCode]);

  useEffect(() => {
    if (isShowSavedSearch && suggestions?.suggestions?.length > 0) {
      setShowSuggestions(true);
    }
  }, [isShowSavedSearch, suggestions]);

  // to update displayed text when passed default text is updated
  useEffect(() => {
    setText(props.defaultText);
  }, [props.defaultText]);

  // to send RAT when place input fetch suggestions successfully
  useEffect(() => {
    if (
      isNotEmptyArray(suggestions?.suggestions) &&
      text.length > 0 &&
      isActivateSearch &&
      !isLoading &&
      !isPending
    ) {
      // https://confluence.rakuten-it.com/confluence/pages/viewpage.action?pageId=1990325579#SetupforWEBMeasurement-6.TrackingAnyAsynchronizedeventsbycustomcode
      const customEvent = { includeInput: true, options: ['url', 'ua'], eventType: 'async' };
      window.RAT && window.RAT.addCustomEvent(customEvent);
    }
  }, [isActivateSearch, suggestions?.suggestions]);

  const onSelectPlace = (selectedItem: SuggestionsItem, isFromOnBlur?: boolean) => {
    if (selectedItem?.pathId) {
      // if the selected item has a path id then hide the show suggestions
      setShowSuggestions(false);
      setIsPending(false);
      setText(selectedItem.name);
      lastSelectedText.current = selectedItem.name;
      onMobileDialogClose();

      if (isSaveSearchPage || props.onPage === 'area') {
        const isSavedSearch = isSaveSearchPage && selectedItem.isSavedSearch;
        const hasDate = Boolean(
          isSavedSearch && selectedItem?.query?.startDate && selectedItem?.query?.endDate,
        );
        props.onSelectPlace?.(isSavedSearch, hasDate, isFromOnBlur);
      }

      dispatchRatEvent();

      // XXX: without setTimeout the setXXX will be batched, but here we want to make sure the props.onChangePlace is called
      // after all of the setXXX are finished
      setTimeout(() => {
        props.onChangePlace(selectedItem);
      });
    }

    setIsActivateSearch(false);
  };

  const onFocus = () => {
    setIsActivateSearch(true);
    if (text.length === 0 && !isSelectSuggestedItem.current && isSaveSearchPage) {
      setIsShowSavedSearch(true);
    }
  };

  const handleOnClose = useCallback(() => {
    clearTimeout(blurTimeoutFunc.current as number);
    setText(lastSelectedText.current);
    onMobileDialogClose();
  }, [setText, onMobileDialogClose]);

  useOutsideClick(clickAwayRef, inputEl, () => {
    setShowSuggestions(false);
    setIsShowSavedSearch(false);
  });

  const getSuggestions = useCallback((): Array<SuggestionsSection> => {
    const hasSearchText = text.length > 0;
    const hasSavedSearches = isNotEmptyArray(savedSearch?.suggestions);
    const hasAPISuggestions = isNotEmptyArray(suggestions?.suggestions);
    if (!hasSearchText && popularDestinations) {
      if (hasSavedSearches || isShowSavedSearch) {
        return [savedSearch, popularDestinations];
      } else {
        return [popularDestinations];
      }
    } else if (isLoading || isPending) {
      return [];
    } else if (hasAPISuggestions) {
      return [suggestions];
    } else if (isShowSavedSearch && hasSavedSearches) {
      return [savedSearch];
    }
    return [EMPTY_PLACES];
  }, [
    savedSearch,
    isLoading,
    isPending,
    suggestions,
    isShowSavedSearch,
    popularDestinations,
    text,
  ]);

  const deleteButton = (suggestion: SuggestionsItem) => {
    return (
      <button
        className={styles.deleteSavedSearch}
        onClick={event => {
          event.stopPropagation();
          event.preventDefault();
          const suggestionString = JSON.stringify({
            ...suggestion.query,
            name: suggestion.name,
          });
          //Delete saved search from local storage
          deleteSavedSearch(suggestionString);

          //Delete saved search from state
          const suggestions = savedSearch.suggestions.filter(item => {
            return JSON.stringify({ ...item.query, name: item.name }) !== suggestionString;
          });

          //If no saved search left, set empty suggestion
          if (suggestions.length === 0) {
            setSavedSearch(EMPTY_SUGGESTION);
          } else {
            setSavedSearch(prevSavedSearch => ({
              ...prevSavedSearch,
              suggestions: suggestions,
            }));
          }
        }}
        data-testid="delete-savedSearch-icon"
        aria-label="Delete Saved Search"
        type="button"
      >
        <Trash />
      </button>
    );
  };

  const placeInputAutoComplete = (
    <div ref={clickAwayRef}>
      <AutoCompleteMulti<SuggestionsItem, SuggestionsSection>
        isAlwaysRenderSuggestions={isShowSavedSearch}
        inputRef={ref => (inputEl.current = ref)}
        className={styles.placeInputAutoComplete}
        isHighlightFirstSuggestion={true}
        shouldFocusInputOnSuggestionClick={isSaveSearchPage ? false : undefined}
        suggestions={getSuggestions()}
        delayTime={DEBOUNCE_TIME}
        value={text}
        onFocus={onFocus}
        shouldRenderSuggestions={() => showSuggestions}
        onBlur={(_event, params) => {
          // onBlur is triggered on selecting a popular destination
          // we want to continue showing suggestions based on the chosen popular destination
          if (params?.highlightedSuggestion?.category === 'POPULAR_DESTINATION') {
            return;
          }

          // why on blur? shouldRenderSuggestions (above) is called when the input receives focus
          // we set showSuggestions to false when the user selects a place
          // we then set showSuggestions to true on blur so that when the input receives focus the next time, the value is already true and the suggestions show
          setShowSuggestions(true);

          isSaveSearchPage && setIsShowSavedSearch(false);

          // isInitialState: to prevent auto-filling when data is ready on page rendered
          // isSelectSuggestedItem: to prevent auto-filling when selected suggested item
          if (
            isPC &&
            !isInitialState.current &&
            !isSelectSuggestedItem.current &&
            text.length !== 0 &&
            params?.highlightedSuggestion
          ) {
            onSelectPlace(params?.highlightedSuggestion, true);
          }
        }}
        onInputChange={(text, method) => {
          setIsActivateSearch(true);
          setShowSuggestions(true);

          // clicking the clear button also blurs the input so to avoid that I clear the blur timeout so that the on blur action doesn't get called
          isInitialState.current = false;
          isSelectSuggestedItem.current = false;
          if (isSaveSearchPage && method !== 'down' && method !== 'up' && text.length !== 0) {
            setIsShowSavedSearch(false);
          }

          if (method !== 'down' && method !== 'up') {
            setText(text);
          }
          // reset place id every time when input empty
          if (
            isEmptyArray(savedSearch) &&
            text.length === 0 &&
            method !== 'clear' &&
            method !== 'enter'
          ) {
            props.onChangePlace?.(EMPTY_PLACES.suggestions[0]);
          } else if ((method === 'clear' || method === 'type') && text.length === 0) {
            // if on isSaveSearchPage and input is cleared
            // should show saved search and popular destinations
            isSaveSearchPage && setIsShowSavedSearch(true);
            setShowSuggestions(false);
            dispatch(updateAreaName(''));
            props.onDeletePlace?.();
          }
          // pending handler
          clearTimeout(pendingTimerRef.current as number);
          if (text.trim().length !== 0 && method !== 'down' && method !== 'up') {
            setIsPending(true);
            pendingTimerRef.current = setTimeout(() => setIsPending(false), DEBOUNCE_TIME);
          } else {
            setIsPending(false);
          }
        }}
        onSuggestionSelected={(_event, { suggestion }) => {
          if (showSuggestions || isShowSavedSearch) {
            _event.preventDefault();
            // prevent default to prevent form submission while the user is hovering over suggestions
          } else {
            // when the user presses enter the first suggestion is selected even if the suggestion dropdown is hidden so prevent that here
            return;
          }
          isSelectSuggestedItem.current = true;
          if (suggestion.isSavedSearch) {
            setIsShowSavedSearch(false);
          }
          if (suggestion.pathId) {
            onSelectPlace(suggestion);
          } else {
            setIsShowSavedSearch(false);
            isSelectSuggestedItem.current = false;
            setText(suggestion.name);
            if (inputEl.current) {
              inputEl.current.focus();
            }
          }
        }}
        onSuggestionsFetchRequested={async text => {
          if (!isShowSavedSearch && !isSelectSuggestedItem.current) {
            await dispatch(
              fetchSuggestions(text, {
                limit: isPC ? SUGGESTION_ITEM_LIMIT_PC : SUGGESTION_ITEM_LIMIT_NON_PC,
                pathIdScope: props.pathIdScope,
              }),
            );
            setShowSuggestions(true);
          }
        }}
        getSuggestionValue={suggestion => suggestion.name}
        getSectionSuggestions={section => {
          return section?.suggestions;
        }}
        renderSuggestion={(suggestion, params) => {
          if (suggestion.category === 'UNDEFINED') {
            return <EmptyState />;
          }
          let startEndDates = null;
          let guests = null;
          let rooms = null;
          if (suggestion.isSavedSearch) {
            if (suggestion?.query?.startDate && suggestion?.query?.endDate) {
              startEndDates = (
                <>
                  <L10nDate format="MD" value={suggestion.query.startDate.toString()} />
                  &nbsp;-&nbsp;
                  <L10nDate format="MD" value={suggestion.query.endDate.toString()} />
                  &nbsp;|&nbsp;
                </>
              );
            }
            if (suggestion?.query?.guests) {
              const guestCount = Number(suggestion?.query?.guests);
              guests = (
                <>
                  <Translate
                    id="Top.Search.Guest"
                    count={guestCount}
                    data={{ guest_count: guestCount }}
                  />
                  &nbsp;|&nbsp;
                </>
              );
            }
            if (suggestion?.query?.noOfUnits) {
              const roomCount = Number(suggestion.query.noOfUnits);
              rooms = (
                <Translate
                  id="Top.Number_of_Guests.Room"
                  count={roomCount}
                  data={{ room_count: roomCount }}
                />
              );
            }
          }
          return (
            <button
              type="button"
              data-testid={`button-${suggestion.category}-${suggestion.pathId}`}
              className={cx(
                styles.suggestion,
                styles.savedSearch,
                params?.isHighlighted && styles.highlighted,
              )}
              tabIndex={-1}
            >
              {suggestion.isSavedSearch ? (
                <>
                  <div className={styles.savedSearchIcon}>{ICON_MAP.SAVED_SEARCH}</div>
                  <div className={styles.savedSearchText}>
                    <div className={styles.savedSearchPlace}>{suggestion.name}</div>
                    <div className={styles.savedSearchQuery}>
                      <>{startEndDates}</>
                      <>{guests}</>
                      <>{rooms}</>
                    </div>
                  </div>
                  {deleteButton(suggestion)}
                </>
              ) : (
                <>
                  <span className={styles.suggestionIcon}>{ICON_MAP[suggestion.category]}</span>
                  {suggestion.name}
                </>
              )}
            </button>
          );
        }}
        renderSectionTitle={(section: any) => {
          return section.title;
        }}
        renderSuggestionsContainer={({ containerProps, children }) => {
          if (text.length === 0 && isNotEmptyArray(savedSearch?.suggestions) && isShowSavedSearch) {
            return (
              <div
                {...containerProps}
                className={cx(
                  isPC && styles.pcSuggestionContainer,
                  isActivateSearch && styles.active,
                )}
                data-testid="placeInput-savedSearch-container"
              >
                {children}
              </div>
            );
          } else {
            return (
              <div
                {...containerProps}
                className={cx(
                  isPC && styles.pcSuggestionContainer,
                  isLoading && styles.loading,
                  isActivateSearch && styles.active,
                )}
                data-testid="placeInput-suggestion-container"
              >
                <WithSkeletonLoading
                  isLoading={isLoading || isPending}
                  row={1}
                  count={5}
                  length="long"
                  customStyle="textWithImageRow"
                  skeletonItemClassName={styles.skeletonItem}
                  skeletonImageClassName={styles.skeletonImageItem}
                >
                  {children}
                </WithSkeletonLoading>
              </div>
            );
          }
        }}
      >
        <TextField
          inputProps={{
            autoComplete: 'off',
            placeholder: destinationText,
            className: cx(
              styles.searchInput,
              (isOnProviderList || isOnProviderInfo) && styles.withHover,
              (isOnAreaPage || isOnTopPage) && styles.withHoverTopAndArea,
              (isOnAreaPage || isOnTopPage) && props.hasError && styles.hasError,
              isOnProviderInfo && styles.providerInfoStyle,
            ),
            id: 'topPage_search',
          }}
          label={'topPage_search'}
          isLabelHidden
          characterLimit={CHARACTER_LIMIT}
          value={text}
          isDisabled={props.isDisabled}
          shouldHideClearButtonOnBlur={true}
          leadingIcon={<Search />}
          hasError={
            ((isPC || isMobile) && props.hasError) ||
            (isMobile && (isOnProviderList || isOnProviderInfo) && text.length === 0)
          }
          errorMessage={props.errorMessage}
          alertClassName={props.errorMessageClassName}
        />
      </AutoCompleteMulti>
    </div>
  );

  const onOpenButtonClick = () => {
    onMobileDialogOpen();
    setTimeout(() => {
      if (inputEl.current) {
        inputEl.current.focus();
      }
    }, 20);
  };

  return (
    <div data-testid="placeInputAutoComplete-div-wrapper" className={props.className}>
      {isPC && placeInputAutoComplete}
      {!isPC && (
        <>
          {(props.onPage === 'top' || isCouponSearch || props.onPage === 'area') && (
            <button
              type="button"
              className={cx(
                styles.mainButton,
                !isCouponSearch && styles.mainButtonTopAndArea,
                !isCouponSearch && props.hasError && styles.hasError,
              )}
              disabled={props.isDisabled}
              onClick={onOpenButtonClick}
              data-testid="placeInput-mainButton-button"
            >
              <Search className={styles.searchIcon} size={isCouponSearch && isMobile ? 16 : 24} />
              {text ? (
                <span className={styles.destination}>{text}</span>
              ) : (
                <span className={styles.placeholder}>{destinationText}</span>
              )}
            </button>
          )}
          {(isOnTopPage || isOnAreaPage) && props.hasError && (
            <ErrorMessage
              hasTransparentBackground
              message={props.errorMessage}
              className={styles.topAreaButtonError}
            />
          )}
          {(isOnProviderList || isOnProviderInfo) && (
            <>
              <FlatButton
                classType="secondary"
                icon={<Search size={16} color={isMobile ? 'default' : 'cilantro'} />}
                className={cx(
                  styles.mainButtonProviderList,
                  isOnProviderInfo && styles.mainButtonProviderInfo,
                )}
                labelClassName={styles.placeLabel}
                onClick={onOpenButtonClick}
                isSmallerButtonByDefault
                data-testid="placeInput-mainButton-button"
              >
                {text || destinationText}
              </FlatButton>
              {props.hasError && !isMobile && (
                <ErrorMessage className={styles.errorMessage} message={props.errorMessage} />
              )}
            </>
          )}
          <HtmlDialog
            isOpen={isMobileDialogOpen}
            onClose={handleOnClose}
            title={<Translate id="Top.Modals.Destination.Title" />}
            isIOS={isIOS}
            contentClassName={styles.dialogContent}
            className={styles.dialogClassName}
            headerClassName={styles.dialogHeader}
            isDisplayedOnDialog={isCouponSearch}
            hasAnimation={props.hasAnimation}
          >
            {placeInputAutoComplete}
          </HtmlDialog>
          {isMobileDialogOpen && (
            <DataLayer
              pageName={
                isCouponSearch
                  ? `${parentName}:search_coupon_destination_form_popup`
                  : `${parentName}:search_destination_form_popup`
              }
              pageType={pageType}
              siteSection={siteSection}
            />
          )}
        </>
      )}
    </div>
  );
}

PlaceInput.defaultProps = {
  defaultText: '',
};

export default PlaceInput;
